.email-popup{
    z-index: 50;
    position: fixed;
    border-radius: 9px;
    background-color: #FFFFFF;
    padding: 3rem 2rem 2rem 2rem;
    top: 50vh;
    box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.email-popup__close {
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    cursor: pointer;

}
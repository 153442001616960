.status{
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.description{
    color: #2F353B;
    font-size: 1.2rem;
    margin: 1.6rem;
}

.add-comments,
.save-comment {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: 1rem;
}

.comment {
    margin: 1rem;
}

.attachments {
text-align: right;
}

.feature-attachment {
    width: 70%;
}

.comment-attachment {
    width: 50%;
}

.spinner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
}
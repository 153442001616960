.background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    background-color: #EFF1F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.message-box {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.12);
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error {
    color:#2E3032;
    font-size: 1.5rem;
    margin: 0;
}

.header {
    color: #323B4B;
    font-weight: 800;
    font-size: 2rem;
    margin: 0;
}

.message {
    color: #4E5D78;
    font-size: 1.125rem;
    text-align: center;
    margin: 3rem 0;
}
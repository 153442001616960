.btn-o {
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.75rem;
    border: 1px solid #637E1F;  
    color: #637E1F;
    cursor: pointer;
    gap: 0.5rem;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 900;
}

.btn-o:hover {
    color: #49620E;
    background-color: #F3FFD6;
    border: 1px solid #D7EEA1;
}

.btn-o:active {
    background-color: #D7EEA1;
    color: #2C3C05;
    border: 1px solid #D7EEA1;
}

.btn-o:disabled {
    background-color:  #EFF1F3;
    border-color: #B0B7C3;
    color:#B0B7C3;
    cursor: auto;
}


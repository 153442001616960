
.first-row{
    display: flex;
    gap: 2.5rem;
}

.id,
.add-date{
    color: #8A94A6;
    font-size: 0.875rem;
}

.fields{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

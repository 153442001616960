.btn {
    background: #70921A;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.75rem;
    border: none;
    color: #F7F8F9;
    cursor: pointer;
    gap: 0.5rem;
    font-family: inherit;
    font-size:  0.94rem;
    font-weight: 900;
}

.btn:hover {
    color: #FFFFFF;
    background-color: #637E1F;
}

.btn:active {
    background-color:  #49620E;
    color: #FFFFFF;
}

.btn:disabled {
    background-color:  #EFF1F3;
    color: #8A94A6;
    cursor: auto;
}

.delete {
    background-color: #8B1A1C;
}

.delete:hover{
    background-color: #D72F19;
}

.delete:active{
    background-color: #D72F19;
}

.spinning {
    animation: spinner 3s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}


.checkbox-wrapper {
    display: flex;
    align-items: center;
}

.checkbox {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.25rem;
    height: 1.25rem;
    border-radius:4px;
    margin-right: 0.5rem;
    border: 1px solid #C8CCD2;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    outline: none;
    cursor: pointer;
  }

  .checkbox-wrapper:hover .checkbox {
    border: 1px solid #70921A;
  }

  .checked .checkbox {
    border: 1px solid #70921A;
    background-color: #70921A;
    background-image: url('../../../images/CheckboxW.png');
    background-position: center;
    background-size: 100%;
    position: relative;
    font-family: sans-serif;
  }

  .checkbox-wrapper.checked:hover .checkbox {
    background: #49620E;
    background-image: url('../../../images/CheckboxW.png');
    background-position: center;
    background-size: 100%;
  }

  .checkbox-wrapper.checked.small input::before{
    font-size: 1rem;
  }

  .checkbox-wrapper .checkbox:disabled,
  .checkbox-wrapper.readonly .checkbox {
    border-color: #c0c0c0;
    background-color: #F7F8F9;
    cursor: default;
  }

  .checkbox-wrapper.checked .checkbox:disabled,
  .checkbox-wrapper.readonly.checked .checkbox {
    background-color: #D7EEA1;
    border-color: #B3D95A;
    cursor: default;
  }


  .checkbox-wrapper.checked:hover .checkbox:disabled,
  .checked.readonly:hover .checkbox {
    background-color: #D7EEA1;
    border-color: #B3D95A;
    cursor: default;
  }
  
  .checkbox:disabled + label,
  .readonly .checkbox + label {
    color: #c0c0c0;
  }
  
  .checkbox-wrapper.small .checkbox {
    height: 1rem;
    width: 1rem;
}
.detail-item {
    border: 1px solid #B1B5BA;
    border-radius: 9px;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.04);
    background: #FFFFFF;
}
.detail-item__header {
    background: #F5F7FA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.25rem;
    cursor: pointer;
    border-radius: 9px;
}

.detail-item__header-open {
    border-radius: 9px 9px 0 0;
}

.header {
    display: flex;
    gap: 0.94rem;
}

.title {
    color: #2F353B;
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin: 0;
}

.detail-item__body {
    padding: 1rem 1.25rem 1rem 1.25rem;
}
main {
    display: flex;
    flex-direction: column;
}

.reminder-wrapper {
    margin-top: 1.3rem;
    flex: 1;
    padding-left: 1.625rem;
    margin-right: 1.8rem;
    overflow: hidden;
    padding-bottom: 0.625rem;
}

.reminder-list__header {
    position: relative;
    height: 3.2rem;
    color: #8A94A6;
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 1.4rem;
    display: flex;
    align-items: center;
}

.reminder-list__header p {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.reminder-list__header img {
    cursor: pointer;
}

.reminders-list {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    height: calc(100% - 3.2rem);
    width: 100%;
    overflow-y: scroll;
    flex-wrap: wrap;
    padding-right: 0.9rem;
    color: #2f2524;
    align-content: flex-start;
}

.id {
    left: 3%;
    font-weight: 800;
    width: 6%;
}

.donorid {
    left: 11%;
    font-weight: 800;
    width: 6%;
}

.donor{
    left: 19%;
    width: 16%;
}

.name {
    left: 37%;
    width: 15%;

}

.type {
    left: 54%;
    width: 10%;

}

.date {
    left: 66%;
    width: 11%;

}


.status{
    position: absolute;
    right: calc(3% + 10rem);
    width: 5rem;
    line-height: 1.25rem;
    margin: 1.25rem 0;
}

.details {
    right: 3%;
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
}

div p.details:hover,
div p.date:hover{
    padding-right: 0;
}

.btn-load-more {
    background-color: #D9DDE3;
    border-radius: 9px;
    color: #5C5E61;
    width: 100%;
    height: 3.75rem;
    cursor: pointer;
    border: none;
    font-weight: 900;
    font-family: inherit;
}

div .mobile-status {
    display: none;
    position: absolute;
}

@media (max-width: 65rem) {
    .reminder-list__header {
        display: none;
    }

    .reminder-wrapper {
        padding-right: 0.94rem;
        padding-left: 0.94rem;
        margin-right: 0;
        overflow: auto;
        bottom: 0;
    }

    .reminders-list {
        overflow: hidden;
        height: auto;
        padding-right: 0;
    }

    div.reminder {
        height: 7rem;
    }

    .id {
        width: 28%;
        top: 0.7rem;
    }

    .id:before {
        content: "Reminder Id: ";
    }

    .donorid {
        left: 3%;
        top: 2.4rem;
        width: 28%;
    }

    .donorid:before {
        content: 'Donor Id: ';
    }

    .name {
        top: 4.1rem;
        left: 3%;
        width: 25%;
    }

    .date {
        left: 27%;
        top: 0.7rem;
        width: 20%;
        text-align: left;
    }


    .type {
        left: 27%;
        top: 2.4rem;
        width: 20%;
        text-align: left;
    } 

    .details {
        left: auto;
        bottom: 0.7rem;
    }
}

@media (max-width: 40rem) {

    div.reminder {
        height: 8.5rem;
    }

    .id {
        width: 53%;
        top: 0.5rem;
    }

    div p.donorid {
        display: none;
    }

    .name {
        width: 90%;
        top: 2.2rem;
    }

    div p.date {
        top: 3.9rem;
        left: 3%;
        text-align: left;
        width: 53%;
    }



    div p.type {
        display: none;
    }

    .details {
        bottom: 0.5rem;
    }

    label.mobile-status {
        display: inherit;
        top: 6rem;
        left: 3%;
    }
}
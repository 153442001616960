.popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: max-content;
    color:  #2F353B;
    z-index: 4;
    right: 0;
  }

  .popup-item,
  .popup-item__delete {
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
  }

  .popup-item:hover,
  .popup-item__delete:hover{
    background-color: #EFF1F3;
  }

  .popup-item{
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .popup-item img {
    width: 1.5rem;
  }
  .popup-item__delete{
    color: #D72F19;
    border-top: 1px solid #D9DDE3;
  }

  .no-actions{
    color: #2F353B;
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
  }

  .snooze-popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 11rem;
    color:  #2F353B;
    z-index: 2;
    right: 101%;
    top: 0;
  }

  .loading {
    cursor: wait;
}

.custom-date {
  margin:  0 0.5rem;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  opacity: 0;
  background-color: #F5F7FA;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-out;
  width: max-content;
  color: #2F353B;
  pointer-events: none; /* Prevent flickering */
  font-size: 0.85rem;
}

.top {
  bottom: 100%;
}

.bottom {
  top: 100%;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #F5F7FA transparent transparent transparent;
}

.bottom::after {
  top: auto;
  bottom: 100%;
  border-color: transparent transparent #F5F7FA transparent;
}

.tooltip:hover .tooltip-text {
  opacity: 1;
}

.sort-button {
    background-color: #D7EEA1;
    height: 2.25rem;
    width: 2.5rem;
    color: #1F3161;
    border-radius: 6px;
    margin: 0rem 1.5rem 0rem 0rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.sort-popup {
    position: absolute;
    width: 500%;
    left: -250%;
    top: 2.5rem;
    border-radius: 9px;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    z-index: 1;
}

div.wrapper {
    width: 100%;
}

.sort {
    display: none;
}

@media (max-width: 65rem) {
    .sort {
        display: inherit;
    }
}

@media(max-width: 40rem){
    .sort-button{
        margin-right: 0.94rem;
    }
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background: rgba(5, 16, 52, 0.3);
    backdrop-filter: blur(20px);
}

.headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.statement {
    display: flex;
    overflow: hidden;
    padding-bottom: 0.625rem;
    flex: 1 1;
}
.right-side {
    width: 20%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.buttons-right-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.add-payment {
    width: 100%;
    max-width: 15rem;
}

.school-details div{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.school-details div p {
    margin: 0.3rem 0;
}

.school-details div a {
    margin: 0.3rem 0;
}

.statement-br{
    display: none;
}

@media(max-width: 65rem){
    .statement {
        flex-direction: column-reverse;
    }

    .right-side {
        width: 100%;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: flex-start;
        align-items: center;
    }

    .school-details {
        width: fit-content;
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 4.5rem;
        justify-content: flex-end;
        column-gap: 1rem;
    }

    .school-details h4 {
        margin: 0;
        height: 2.15rem;
        line-height: 2.15rem;
    }

    .buttons-right-side {
        width: fit-content;
        flex-grow: 1;
        justify-content: flex-end;
        flex-direction: row;
    }

    
}

@media(max-width: 33rem){
    .statement-br{
        display: inherit;
    }
}
@media(max-width: 29rem){
    .school-details {
        height: auto;
    }
}


.fixed-bar {
    position: fixed;
    bottom: 0%;
    width: 62%;
    /*background: rgba(255, 255, 255, 0.1);*/
    border: 1px solid #D9DDE3;
    background-color: rgba(255, 255, 255, .9);
    height: 5rem;
    padding: 1.375rem 1.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 21;
    background-color: #F5F7FA;
}

@media(max-width: 65rem){
    .fixed-bar{
        width: 80%;
    }
}

@media (max-width: 40rem){
    .fixed-bar {
       width: 100%;
    }

}

  
  /* if backdrop support: very transparent and blurred 
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .fixed-bar {
        background: rgba(255, 255, 255, 0.1);
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);

    }
  }*/
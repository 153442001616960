.top-menu{
    height: 5rem;
    width: 100%;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    line-height: 5rem;
    padding-left:1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFF;
}
.top-menu p {
    margin: 0;
    line-height: 5rem;
}

.top-menu__menu {
    text-decoration: none;
    color: #B0B7C3;}

.top-menu__icons {
    display: flex;
    align-content: center;
    align-items: center;
}

.top-menu__icon {
    margin-left: 1.8rem;
}

.top-menu__icons img {
    display: block;
}

.top-menu__icons .top-menu__icons--username {
    height: 2.75rem;
    text-decoration: none;
    margin-left: 2.7rem;
    margin-right: 2.75rem;
    text-align: center;
    color: white;
    font-weight: 900;
    position: relative;
    cursor: pointer;
}


.top-menu__username {
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    border-radius: 50%;
    background-color: #637E1F;
}

.top-menu__logo {
    display: none;
}

.top-menu__hemburger {
    display: none;
}

@media (max-width: 65rem) {
    .top-menu {
        background-color: #262829;
        padding-left: 0;
    }

    .top-menu__menu {
        display: none;
    }

    .top-menu__logo {
        display: inherit;
    }

    .top-menu__hemburger {
        display: inherit;
        cursor: pointer;
    }
}




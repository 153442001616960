main{
    display: flex;
    flex-direction: column;
}

.features{
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    padding-right: 0.9rem;
    color: #2f2524;
    align-content: flex-start;
}

.header {
    display: flex;
    justify-content: space-between;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
    gap: 1rem;
    width: fit-content;
    /* text-align: right; */
    align-self: flex-end;
    position: relative;
}

.features-description {
    padding: 2rem;
    gap: 0;

}

.features-description a{
    color: #637E1F;
}
.features-description a:hover{
    color: #70921A;
}
.features-description__1 {
    font-weight: 700;
    font-size: 1.4rem;
}

.features-description__1 span {
    font-weight: 900;
}

.features-description__2 {
    font-size: 1.1rem;
}

.features-description__3 {
    text-align: left;
    list-style: disc;
}

.features-page {
    display: flex;
    flex: 1;
    overflow: hidden;}

.implemented-features {
    width: 35%;
    background-color: #D7EEA1;
    margin: 1rem;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    margin-left: 0;
    margin-bottom: 0;
    overflow-y: auto;
    margin-top: 3.2rem;
}

.implemented-features__title {
    color: #2F353B;
    padding-left: 1rem;
}

@media(max-width: 75rem){
    .implemented-features{
        margin-top: 0;
    }
}

@media(max-width:45rem){

    main {
        overflow-y: auto;
    }
    .features-page{
        flex-wrap: wrap;
        flex: initial;
        overflow: initial;
    }

    .implemented-features {
        width: 100%;
        margin: 0.9rem;
    }

    .add-feature {
        position: relative;
        width: 80%;
        margin: auto;
        top: 1rem;
        right: 0;
    }
}


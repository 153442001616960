div.message-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: fit-content;
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 21;
  }
  
  .message {
    height: auto;
    width: 40vw;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 2rem 0;
    gap: 2.5rem;
    text-align: center;
    position: relative;
  }

.message-header {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  height: 3.75rem;
  width: 100%;
  padding: 1.25rem;
}  

.message-title {
  flex-grow: 1;
  text-align: left;
  color: #2C3C05;
  font-weight: 900;
  font-size: 1.125rem;
}

.message-close {
  cursor: pointer;
}

@media (max-width: 68rem){
  .message {
    width: 75vw;
  }
}

@media (max-width: 40rem){
  .message {
    width: 95%;
  }
}
.notification-wrapper {
    position: fixed;
    z-index: 40;
    height: 100vh;
    top: 0;
    width: 100vw;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .notification {
    height: auto;
    width: 40vw;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6.5rem 1rem 2rem 1rem;
    gap: 2.5rem;
    text-align: center;
    position: relative;
  }

  .notification__close {
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    cursor: pointer;
  }
  
  .notification p {
    font-size: 1.125rem;
    font-weight: 900;
    margin: 0;
    color: #4E5D78;
    padding: 0 2rem;
  }

  p.notification-link {
    cursor: pointer;
    color: #8A94A6;
  }
  
  p.notification-link:hover{
    color: #323B4B;
  }
  .error {
    color: #D72F19;
  }
  
  .success {
    color: #3D6300;
  }
  

  .notification-buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

@media(max-width: 65rem){
  .notification{
    width: 65%;
  }
}
@media (max-width: 40rem){
  .notification {
    width: 95%;
  }

  .notification-buttons {
    gap: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

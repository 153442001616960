.search {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 36px;
    height: 3rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.search:hover {
    border-color: #B3D95A;
}

.search:focus-within {
    border-color: #70921A;;
}

.search input {
    border: none;
    font-family: inherit;
    font-size: 1rem;
    width: 90%;
}

.search input::placeholder {
    color: #8A94A6;
    font-size: 1rem;
}

.search input:focus,
.search input:focus-visible {
    border: none;
    outline: none;
}

.search img {
    height: 1.3rem;
    width: 1.3rem;
    margin: 0.85rem;
}

.donors__results {
    position: absolute;
    right: 1.125rem;
    color: #B0B7C3;
    font-size: 0.875rem;
}

ul.options {
    background: #FFFFFF;
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    list-style: none;
    margin-top: 4rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    padding-left: 0;
    max-height: 22rem;
    overflow: auto;
  }
    
  .no-results{
    color: #B0B7C3;
    text-align: center;
  }


main {
    display: flex;
    flex-direction: column;
}

.statement-view__wrapper {
    flex: 1;
    padding-left: 1.625rem;
    overflow: hidden;
    padding-bottom: 0.625rem;
    display: flex;
    flex-direction: column;
}

.successful-payments {
    margin-right: 1rem;
    justify-content: flex-end;
}

.successful-payments label {
    color: #2F353B;
}

.statement-view__header {
    position: relative;
    height: 3.2rem;
    color: #8A94A6;
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 1.4rem;
    display: flex;
    align-items: center;
}

.statement-view__header p {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 0.2rem;

}
.statement-view__header p img{
    cursor: pointer;
}

.statement-view__list {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    height: calc(100% - 3.2rem);
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    padding-right: 0.9rem;
    color: #2f2524;
    align-content: flex-start;
}

.view-item {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    width: 100%;
    height: 3.75rem;
    position: relative;
    color: #2F353B;
    display: flex;
    align-items: center;
}

.prev-balance-item,
.running-balance-item{
    background-color: #D9DDE3;
}


.running-balance-item{
    position: sticky;
    bottom: 0;
}

.view-item p {
    position: absolute;
    display: inline-block;
    margin: 0;
    line-height: 3.75rem;
    height: 3.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.value {
    left: 3%;
    font-weight: 800;
    width: 10%;
}

.balance {
    left: 15%;
    width: 11%;
}

.date {
    left: 28%;
    width: 11%;
}

.type {
    left: 41%;
    width: 15%;
    position: absolute;
}

.prev-balance{
    left: 41%;
    
}

.student {
    left: 58%;
    width: 15%;
}

.details {
    left: 74%;
    width: 11%;
}

.view-details {
    right: 3%;
    max-width: 10%;
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
}


.spinner {
margin-top: 5rem;
}

@media(max-width: 65rem){
    .statement-view__wrapper {
        width: auto;
        padding-left: 0.94rem;
    }
}

@media (max-width: 40rem) {
    .statement-view__header{
        display: none;
    }

    .view-item {
        height: 6rem;
        align-items: flex-end;
    }

    .value{
        left: 5%;
        width: 18%;
    }

    .balance{
        left: 25%;
        width: 20%;
    }

    .date{
        top: 5%;
        left: 5%;
        width: auto;
    }

    .type{
        bottom: 20%;
        width: 25%;
    }

    .details{
        top: 5%;
        left: 30%;
        width: auto;
    }

    .student {
        top: 5%;
        left: 60%;
        width: 30%;
    }

    .view-details{
        width: auto;
        max-width: none;
        right: 5%;
    }

    .prev-balance {
        top: 5%;
        left: 5%;
        width: auto;
    }
}

@media(max-width: 33rem){

    .view-item {
        height: 8rem;
    }

    .view-item p {
        height: 2rem;
        line-height: 2rem;
    }

    .type{
        left: 35%;
        top: 5.4rem;
        width: 30%;
    }

    .details{
        left: 35%;
        width: 60%;
        top: 3rem;
    }
    
    .value{
        width: 28%;
        top: 3rem;
    }

    .date {
        top: 1rem;
    }

    .balance{
        left: 5%;
        top: 5rem;
        width: 28%;
    }

    .student {
        right: 5%;
        left: auto;
        width: auto;
        max-width: 45%;
        top: 1rem;
    }
}
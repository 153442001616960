.container {
    width: 20px;
    height: 20px;

}

.loader {
    width: 18px;
    height: 18px;
    border: 2px solid #B1B5BA;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loader::after {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 2px solid transparent;
      animation: rotation 1s linear infinite;
      border-bottom-color: #637E1F;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 


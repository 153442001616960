.completed {
    display: flex;
    gap: 0.94rem;
}

.completed-text {
    margin: 0;
    color: #2F353B;
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.375rem;
}

.action{
    height: 1.5rem;
    cursor: pointer;
    position: relative;
}


.popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 13.9rem;
    color:  #2F353B;
    z-index: 2;
    right: 0;
  }

  .popup-item{
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .popup-item:hover{
    background-color: #EFF1F3;
  }

  .no-actions{
    color: #2F353B;
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: default;
  }



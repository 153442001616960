.donor-search__wrapper {
    display: flex;
    gap: 1rem;
}

.donor-search {
    width: 20rem;
}

.filter {
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.625rem;
}
@media(max-width: 27rem){
    .donor-search__wrapper{
        flex-wrap: wrap-reverse;
    }
}


.main-sidebar{
    position: fixed;
    left: 0;
    top: 5rem;
    width: 100%;
    background-color: #0E181D;
    z-index: 4;
}

.main-sidebar__items{
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-sidebar__item{
    height: 4rem;
    line-height: 4rem;
    margin: 0.5rem 0 0 0;
    width: 100%;
    vertical-align: middle;
}

.main-sidebar__nav {
    height: 100%;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding-left: 2.5rem;

}

.main-sidebar__item div{
    display: inline-block;
    vertical-align: middle;
}

.main-sidebar__item img {
    vertical-align: middle;

}

.main-sidebar__item label{
    color: white;
    font-size: 18px;
    line-height: 27px;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 1rem;
}
.main-sidebar__nav:hover,
.main-sidebar__nav:active,
.main-sidebar__nav .active {
    background-color: #2E3032;
}


.main-sidebar__nav:hover label,
.main-sidebar__nav:active label,
.main-sidebar__nav.active label {
    color: #70921A;
}

@media (min-width: 65rem) {
    .main-sidebar {
        display: none;
    }
}


.search-donor__item {
    background: #FFFFFF;
    min-height: 5.6rem;
    cursor: pointer;
    padding:0 0.94rem;
    position: relative;
  }

  .search-donor__item p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .search-donor__item:active,
  .search-donor__item.active,
  .search-donor__item:focus,
  .search-donor__item:hover {
    background: #F4F4F4;;
  }

  .search-donor__item:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  .search-donor__item:last-of-type {
    border-radius: 0 0 8px 8px;
  }


.search-donor__name {
    color: #2F353B;
    font-weight: 900;
    height: 1.5rem;
    line-height: 1.5rem;
    margin:0.25rem 0;
    width: calc(100% - 5rem);
}

.search-donor__id {
    position: absolute;
    right: 0.94rem;
    top: 0.15rem;
    height: 1.375rem;
    line-height: 1.375rem;
    font-size: 0.875rem;
    color: #8A94A6;
    margin: 0;

}

.search-donor__phone {
    color: #323B4B;
    font-size: 0.875rem;
    display: flex;
    gap: 0.8125rem;
    align-items: center;
}

.search-donor__phone p {
    margin: 0;
}

.search-donor__address {
    line-height: 1.25rem;
    margin: 0;
    color: #8A94A6;
    font-size: 0.8125rem;
    font-weight: 900;
}

.search-donor__hr {
    width: 90%;
}


@media((max-width: 60rem) and (min-width:40rem)){
  .devider {
    display: none;
  }

  .search-donor__phone {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
}

.buttons{
    display: flex;
    gap: 1rem;
}

.top{
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.buttons img{
    cursor: pointer;
}
.loading,
.loading img{
    cursor: wait;
}


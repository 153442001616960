.text-area__wrapper {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 0.25rem 0.75rem 0.75rem 0.75rem;
}

.text-area__wrapper:hover{
    border-color: #70921A;
}

.text-area__wrapper label {
        display: block;
        color: #8A94A6;
        font-size: 0.75rem;
        line-height: 1.125rem;
}
.text-area__wrapper:focus-within label {
    color: #49620E;
}

.textarea {
    width: 100%;
    height: auto;
     resize: none;
     border: none;
     color: #2F353B;
    line-height: 1.5rem;
    font-size: 1rem;
    font-family: inherit;
    white-space: pre-wrap;
    min-height: 3rem;
}

.textarea:focus-visible {
    outline: none;
}

.required-input {
    color: red;
}

.invalid-input {
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}
.invalid-input:focus-within {
    border-color: #D72F19;
}

.invalid-input div:focus {
    color: #8B1A1C;
}

.invalid-input {
    background-color: #FFEAE9;
}

.invalid-input label {
    color: #8B1A1C;
}

.invalid-input {
    color: #8B1A1C;
}


.queries {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
}

.captions {
    display: flex;
    background-color: #EFF1F3;
    padding: 1px;
    border-radius: 6px;
    gap: 0.25rem;
    width: max-content;
}

.caption {
    color: #8A94A6;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1.25rem;
    margin: 0;
    padding: 0.125rem 0.75rem;
    cursor: pointer;
}

.active {
    border: 1px solid #D9DDE3;
    color: #2F353B;
    font-weight: 800;
    background-color: #FFFFFF;
    border-radius: 4px;
}

.rows {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 10rem;
    overflow: auto;
}

.headers,
.row {
    display: flex;
    width: 100%;
}

.header{
    color: #2F353B;
    font-size: 0.75rem;
    font-weight: 800;
    margin: 0;
    line-height: 1.25rem;
    flex: 1;
    cursor: pointer;
}

.row-value{
    color: #2F353B;
    font-size: 0.75rem;
    font-weight: 350;
    margin: 0;
    line-height: 1.25rem;
    flex: 1;
}

@media(max-width: 65rem){
    .rows{
        max-height: 16rem;
    }
}
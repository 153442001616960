.badge {
    height: 1.25rem;
    line-height: 1.25rem;
    border-radius: 64px;
    padding: 0rem 0.94rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
}
.regular {
    color: #5C5E61;
    background-color: #EFF1F3;
}

.error {
    color: #D72F19;
    background-color: #FFEAE9;
}

.success {
    color: #3D6300;
    background-color: #E6F6EC;
}

.info {
    color: #57A2FA;
    background-color: #EBF4FF;
}

.warning {
    color: #E99D42;
    background-color: #F8F0D1;
}
.container{
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
}

.totals {
    display: flex;
    gap: 2.5rem;
    flex-wrap: wrap;
}

.name {
    margin: 0;
    font-weight: 350;
    font-size: 0.875rem;
    color: #4E5D78;
    line-height: 1.375rem;
}

.value {
    font-size: 1.125rem;
    font-weight: 800;
    color: #2F353B;
    line-height: 1.6875;
    margin: 0;
}

.bars {
    display: flex;
    flex-direction: column;
    width: 6.625rem !important;
}

.bar-total{
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.total-numbers {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 3rem;
    justify-content: flex-end;

}

.amount {
    color: #2F353B;
    font-weight: 800;
    font-size: 0.625rem;
    line-height: 1rem;
    margin: 0;
}

.compression {
    color: #2F353B;
    font-weight: 350;
    font-size: 0.625rem;
    line-height: 1rem;
    margin: 0;
}

.bar {
    height: 0.5rem;
    margin: 0;
    border-radius: 1px;
    flex: 1;
}


.backdrop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 30; 
}

.model {
    height: auto;
    display: flex;
    flex-direction: column;
    position: static;
    
}

.white{
    border-radius: 24px;
    padding: 1.875rem;
}

.white form{
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.date {
    margin-bottom: 0;
    width: 49%;
}


.dropdowns {
    display: flex;
    gap: 0.625rem;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-end;
}

div.name,
div.type {
    flex: 1;
}

div.parent {
    flex: 1;
}

.parent-wrapper {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 0.25rem 0.75rem 0.375rem 0.75rem 
}


.parent-label {
    display: block;
    color: #8A94A6;
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin: 0;
}

.connected-parent{
    display: flex;
    gap: 1rem;
}

.parentid {
    margin: 0;
    color: #2F353B;
    font-weight: 700;
}

.parent-name {
    margin: 0;
    color: #2F353B;
}

.remove-parent {
    margin-left: auto;
}

.invalid {
    margin: 0;
    color: #D72F19;
    position: relative;
}

.checkboxes-shul{
    display: flex;
    gap: 2rem;
}

.checkboxes{
    display: flex;
    gap: 2rem;
}

@media(max-width: 46rem){
    .dropdowns{
        flex-direction: column;
        gap: 1.5rem;
    }

    .date,
    div.campaign,
    div.shul{
        width: 100%;
    }
}
.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
    background-color: #70921A;
    color: #ffffff;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 0.94rem;
    font-weight: 900;
    font-family: inherit;
    border: none;
}

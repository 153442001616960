.c-multi-select-dropdown {
    position: relative;
    font-size: 1rem;
}

.multi-select__input {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 1.25rem 0.75rem 0.25rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: rem;
    margin-bottom: 0.625rem;
}

.multi-select__input:hover {
    border-color: #70921A;
}
.multi-select__label {
    font-size: 0.75rem;
    color: #8A94A6;
    line-height: 1rem;
    position: absolute;
    top: 0.25rem;

}    


.multi-select__selected {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

.multi-select__selected-option {
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 0 2px;
    min-height: 1.3rem;
}

.multi-select__selected-option p {
    font-size: 0.85rem;
    padding: 0.1875rem 0.1875rem 0.1875rem 0.375rem;
    margin: 0;
}


.multi-select__selected-option div {
    cursor: pointer;
    padding: 0 0.25rem;
    height: 100%;
    align-items: center;
    border-radius: 2px;
    display: flex;
}

.multi-select__selected-option img {
    width: 12px;
}

.multi-select__arrow {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.multi-select__selected-option div:hover {
    background-color: rgb(255, 189, 173);
}

.multi-select__input input {
    /*flex:1;*/
    border: none;
    background: none;
    font-size: 1rem;
    height: 1.3rem;
    width: 40%;
}

.multi-select__input input:focus-visible {
    outline: none;
}

.multi-select__clear {
    width: 12px;
}



.multi-select__options {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    list-style: none;
    padding: 5px 0px;
    z-index: 2;
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    background-color: #FFFFFF;
    max-height: 20rem;
    overflow: auto;
}

.multi-select__option {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    background: #FFFFFF;
    height: 3.75rem;
    line-height: 3.75rem;
    cursor: pointer;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    overflow: hidden;

}

.multi-select__option:hover,
.multi-select__option.active {
    background-color: #eee;
}

.invalid-input.multi-select__input{
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}
.invalid-input:focus-within {
    border-color: #D72F19;
}

.invalid-input input:focus {
    color: #8B1A1C;
}

.invalid-input input {
    background-color: #FFEAE9;
}

.invalid-input label {
    color: #8B1A1C;
}

.invalid-input p {
    color: #8B1A1C;
    margin: 0;
    position: absolute;
}




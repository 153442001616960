@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap');
@font-face{
    font-family: 'Avenir';
    src: url('./Avenir Regular.ttf');
}
* {
    box-sizing: border-box;
}


html {
    font-family: 'Avenir', 'Monserrat',  sans-serif;}

body {
    margin: 0;
}

main {
    padding-left: 12.5rem;
    background-color: #EFF1F3;
    height: 100vh;
    overflow: hidden;
}

a{
    color: #637E1F;
    cursor: pointer;
}

a:hover{
    color:  #B3D95A;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #D9DDE3;
border-radius: 4px;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8C8F93;
    border-radius: 4px;
}

.invalid-message {
    color: #D72F19;
}

.required-input {
    color:red;
}

.hr-with-margin {
    margin: 1.5rem 0
}

.hebrew-input {
    direction: rtl;
    text-align: right;
}

.section-header {
    color: #323B4B;
    margin-top: 1.5rem;
}



.development-mode {
    position: fixed;
    bottom: 0;
    background-color: rgba(52, 52, 49, 0.5);
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    color: black;
    width: 10%;
    right: 0;
    text-align: center;
    line-height: 2rem;
    font-size: 1.1rem;
    font-weight: 900;
    z-index: 100;
    border-radius: 6px 0 0 6px;
}


.trial {
    position: fixed;
    bottom: 0;
    left: 0;  
    padding: 16px;
    margin: 0;
    z-index: 100;
    color: #FFFFFF;
    background-color: #995B11;
    font-size: 1.4rem;
    font-weight: 900;
    border-radius: 16px;
    text-align: center;
}


.trial a {
    color: antiquewhite;
    cursor: pointer;
}

.trial a:hover {
    color: rgb(221, 189, 146);
}

.list-no-found {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    color: #2F353B;
    font-weight: 900;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    padding: 1rem;
}

.h2 {
    margin-left: 1.6rem;
    color:  #2F353B;
    line-height: 36px;
    margin-top: 1.6rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 800;
}

@media (max-width: 65rem) {
    .development-mode {
        width: 35%;
    }

    main {
        padding-left: 0;
    }
}


.popup {
    width: 21rem;
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    z-index: 1000;
    border-radius: 6px;
    animation: show 4.5s;
    padding: 0.75rem;
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
}

@keyframes show {
    0%   {right:-100%;}
       
    12% {right: 0;}

    88% {right: 0;}

    100% {right:-100%;}
    }

.success {
    background-color: #EBF4EC;
    border: 0.5px solid #D7EAD9;
    color: #00632F;
}

.error {
    background-color: #FFEAE9;
    border: 0.5px solid #F4D2D2;
    color: #8B1A1C;
}

.messages {
    display: flex;
    flex-direction: column;
}

.header {
    margin: 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.4rem;
}

.message {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.4rem;
}
.fixed-bar {
     left: 38%;
}

@media(max-width: 65rem){
    .fixed-bar{
        left: 20%;
    }
}

@media (max-width: 40rem){
     .fixed-bar {
         left: 0;
     }
 }

.credentials-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message {
    width: 40%;
    gap: 0;
}

.input {
    width: 80%;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    width: 80%;
}
.tasks-page {
    padding: 2rem 16%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow: auto;
    flex: 1 1;
}

.header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    margin: 0;
    font-weight: 900;
    color: #2F353B;
    line-height: 2.25rem;
}

.spinner-wrapper {
    display: flex;
    padding-top: 10rem;
    position: absolute;
    margin: auto;
    width: -webkit-fill-available;;
}

.other-dashboards{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.other-dashboards h3{
    font-weight: 900;
    color: #2F353B;
}

.other-dashboards div{
    height: 1px;
    background-color: #D9DDE3;
    flex: 1 1;
}

@media(max-width: 86rem){
    .tasks-page{
        padding-left: 2%;
        padding-right: 2%;
    }
}


.filter {
    height: 2.25rem;
    width: 2.5rem;
    background-color:  #D7E0F5;
    color: #1F3161;
    border-radius: 6px;
    margin: 0rem 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 40rem){
    .filter{
        margin: 0 0.94rem;
    }
}
.wrap-circles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .circle {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #D9DDE3;
    overflow: hidden;
  }
  
  .circle.per-75 {
    background-image: conic-gradient(#70921A 75%, #D9DDE3 0);
  }
  
  .circle .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    background: #F5F7FA;
    border-radius: 50%;
    font-size: 1.85em;
    font-weight: 300;
  }
 
.login-form input {
    width: 100%;
}

.login-form__line-remember-me {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
}

.login-form__remember-me label {
    color: #FFFFFF;
}

.sign-in-label {
    display: block;
    text-align: center;
    color: #F3FFD6;
    font-size: 0.875rem;
}

.login-form__line-remember-me p {
    color:  #637E1F;
    cursor: pointer;
    font-size: 0.94rem;
    font-weight: 900;
    text-decoration: none;
    vertical-align: middle;
}

.sign-in-label a:hover{
    color:  #70921A;
}

.sign-in-label a {
    color:  #637E1F;
    cursor: pointer;
    font-size: 0.81rem;
    font-weight: 900;
    text-decoration: none;
}

.error-message {
    color: #D72F19;
    font-weight: 900;
}

.forgot-password{
    color: #637E1F;
    cursor: pointer;
}
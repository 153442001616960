.filter-modal {
    gap: 0;
    grid-gap: 0;
    height: 80%;
}

.filter-header {
font-size: 1.125rem;
font-weight: 900;
color: #2C3C05;
}

.filter-buttons {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.filter-cancel {
    position: absolute;
    left: 5%;
}

@media (max-width: 40rem){
    .filter-cancel {
        display: none;
    }
}
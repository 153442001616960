.donor-name {
    color: #2F353B;
 } 

.donor-phone,
.donor-address,
.donor-email,
.additional-addresses {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.additional-addresses {
    cursor: pointer;
    font-size: 0.94rem;
    font-weight: 900;
    color: #1F3161;
}

.buttons {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
}

@media (max-width: 60rem){
    div.additional-addresses__list {
        width: 100% !important;
    }
}

@media (max-width: 40rem) {
    .donor-address,
    .donor-email {
        flex-wrap: wrap;
        margin: 0.625rem 0;
    }

    .donor-address p,
    .donor-email p {
        margin: 0;
    }

    .additional-addresses {
        width: 100%;
        margin-bottom: 0.625rem;
    }
    
    .additional-addresses p {
        margin-top: 0;
    }

}

.message-box-wrapper {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

.message-box {
    width: 42%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    position: relative;
    z-index: 101;
    padding: 6.25rem 8.6rem 2.5rem 8.6rem;
}

.message-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.message-box h1 {
    margin-bottom: 3.875rem;
    font-size: 2.25rem;
    font-weight: 800;
    text-align: center;
    color:#2F353B;
}


.buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.buttons button:first-of-type {
    font-size: 0.94rem;
    font-weight: 900;
    color:#1F3161;
    background: none;
    border: none;
    cursor: pointer;
}

@media(max-width: 65rem){
    .message-box {
        width: 65%;
        padding-right: 5rem;
        padding-left: 5rem;
    }
}
@media (max-width: 40rem){
    .message-box {
        width: 95%;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .buttons {
        gap: 1rem;
        padding: 1rem;
    }
}

.upload-file {
    margin-top: 1rem;
}


input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #D9DDE3;
    display: inline-block;
    padding: 0 0.74rem;
    cursor: pointer;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #2F353B;
    height: 3rem;
    line-height: 3rem;

}

.uploaded-files {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.uploaded-file {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.uploaded-file span{
    max-width: 15rem;
    overflow: hidden;
}
.uploaded-file img {
    width: 5rem;
}
.popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 14rem;
    color:  #2F353B;
    z-index: 2;
    right: 101%;
    cursor: default;
    max-height: 15rem;
    overflow-y: auto;
    top: 0;
  }

  .loading{
    cursor: wait;
  }
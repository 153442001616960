.actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
}

.action{
    height: 1.5rem;
    cursor: pointer;
    position: relative;
}


.loading {
    cursor: wait;
}

@media(max-width: 32.5rem){
    .actions {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}
.detail-item {
    height: 3.75rem;
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 9px;
    display: flex;
    align-items: center;
    gap: 0.94rem;
    padding: 0 1.25rem;
    margin-bottom: 1.06rem;
    cursor: pointer;
}

.title-closed {
    color: #4E5D78;

}

.detail-item__open {
    background: #FFFFFF;
    border: 1px solid #B1B5BA;
    border-radius: 9px;
    margin-bottom: 1.06rem;
    box-shadow:  0px 4px 10px rgba(0, 0, 0, 0.15);;

}

.detail-item__open-header {
    height: 3.75rem;
    background: #F5F7FA;
    border-radius: 9px 9px 0 0;
    display: flex;
    align-items: center;
    gap: 0.94rem;
    padding: 0 1.25rem;
    cursor: pointer;
}

.detail-item__open-header h3 {
    color: #637E1F;

}

.btn-update {
    margin-left: auto;
}

.detail-item__body {
    padding:0.94rem 1.25rem 1.25rem 1.25rem;
    position: relative;
}

.scroll{
    max-height: 28rem;
    overflow: auto;
}

.closed {
    display: none;
}
.search {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    height: 3rem;
    padding: 0.25rem 0.75rem;
    text-align: left;
}

.search:hover,
.search:focus-within  {
    border-color: #70921A;
}



ul.options {
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    list-style: none;
    margin-top: 4rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    padding-left: 0;
    padding-right: 0;
    left: 0;
    max-height: 25rem;
    overflow: auto;
  }
    
  ul.options li {
    background: #FFFFFF;
    height: 3.75rem;
    line-height: 3.75rem;
    cursor: pointer;
    padding: 0 1rem;
  }
  
  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li.hover-item {
    background: #F4F4F4;;
  }

  ul.options li.selected-item {
    background-color: #70921A;
    color: #FFFFFF;
  }
  
  ul.options li:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  ul.options li:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .select-label {
    display: block;
    color: #8A94A6;
    font-size: 0.75rem;
    text-align: left;
    height: 0.94rem;
}

.select-input {
    background-color: transparent;
    border: none;
    width: 100%;
    padding-left: 0;
    color: #2F353B;
    font-size: 1rem;
    margin: 0;
    font-family: inherit;
}

.select-input:focus {
    caret-color: #70921A;
}

.select-input:focus-visible {
    outline: none;
}

.required-input {
  color: red;
}

.invalid-input.search{
  background-color:  #FFEAE9;
  border-color: #D72F19;
  color: #8B1A1C;
}
.invalid-input:focus-within {
  border-color: #D72F19;
}

.invalid-input input:focus {
  color: #8B1A1C;
}

.invalid-input input {
  background-color: #FFEAE9;
}

.invalid-input label {
  color: #8B1A1C;
}

.invalid-input p {
  color: #8B1A1C;
}

.btn-cancel {
    font-size: 0.94rem;
    font-weight: 900;
    cursor: pointer;
    border: none;
    background: none;
    font-family: inherit;
}

.green {
    color: #637E1F;
}

.blue {
    color: #1F3161;
}

.pink {
    color: #F68686;
}

.gray {
    color: #8A94A6
}
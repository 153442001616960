.popup-item {
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
}

.popup-item:hover{
  background-color: #EFF1F3;
}

.popup-list {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: max-content;
    color:  #2F353B;
    z-index: 2;
    right: 101%;
    top: 0;
  }

  .left{
    left: auto;
    right: 101%;
  }
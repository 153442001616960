.filter-form{
    text-align: left;
    padding: 0 2.5rem;
    width: 100%;
    overflow: auto;
    height: 100%;
}

.select{
    display: inline-block;
    width: 80%;
}

.date-range__header {
    color: #323B4B;
    font-weight: 900;
    font-size: 1rem;
    margin: 0.625rem 0;
}

.date-range {
    display: flex;
    gap: 0.625rem;
}

.date-range__input {
    flex-grow: 1;
    margin-bottom: 0.625rem;
}



.buttons{
    text-align: right;
}

.selected-donors{
    display: flex;
    justify-content: space-between;
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    padding: 0 1rem;
    margin-bottom: 0.625rem;
}

.selected-donors p {
    margin: 0.5rem 0;
}

.selected-donors img{
    width: 1rem;
    cursor: pointer;
}

.name,
.type,
.reminderids,
.description{
    margin-bottom: 0.625rem;
}

.status{
     width: 100%;
}
@media(max-width:25rem){
    .date-range{
        gap: 0;
        flex-direction: column;
    }
}
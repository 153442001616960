@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap);
@font-face{
    font-family: 'Avenir';
    src: url("/static/media/Avenir Regular.b58b1b9c.ttf");
}
* {
    box-sizing: border-box;
}


html {
    font-family: 'Avenir', 'Monserrat',  sans-serif;}

body {
    margin: 0;
}

main {
    padding-left: 12.5rem;
    background-color: #EFF1F3;
    height: 100vh;
    overflow: hidden;
}

a{
    color: #637E1F;
    cursor: pointer;
}

a:hover{
    color:  #B3D95A;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #D9DDE3;
border-radius: 4px;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8C8F93;
    border-radius: 4px;
}

.invalid-message {
    color: #D72F19;
}

.required-input {
    color:red;
}

.hr-with-margin {
    margin: 1.5rem 0
}

.hebrew-input {
    direction: rtl;
    text-align: right;
}

.section-header {
    color: #323B4B;
    margin-top: 1.5rem;
}



.development-mode {
    position: fixed;
    bottom: 0;
    background-color: rgba(52, 52, 49, 0.5);
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    color: black;
    width: 10%;
    right: 0;
    text-align: center;
    line-height: 2rem;
    font-size: 1.1rem;
    font-weight: 900;
    z-index: 100;
    border-radius: 6px 0 0 6px;
}


.trial {
    position: fixed;
    bottom: 0;
    left: 0;  
    padding: 16px;
    margin: 0;
    z-index: 100;
    color: #FFFFFF;
    background-color: #995B11;
    font-size: 1.4rem;
    font-weight: 900;
    border-radius: 16px;
    text-align: center;
}


.trial a {
    color: antiquewhite;
    cursor: pointer;
}

.trial a:hover {
    color: rgb(221, 189, 146);
}

.list-no-found {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    color: #2F353B;
    font-weight: 900;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    padding: 1rem;
}

.h2 {
    margin-left: 1.6rem;
    color:  #2F353B;
    line-height: 36px;
    margin-top: 1.6rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 800;
}

@media (max-width: 65rem) {
    .development-mode {
        width: 35%;
    }

    main {
        padding-left: 0;
    }
}


.Notification_notification-wrapper__chptc {
    position: fixed;
    z-index: 40;
    height: 100vh;
    top: 0;
    width: 100vw;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Notification_notification__2bsoV {
    height: auto;
    width: 40vw;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6.5rem 1rem 2rem 1rem;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    text-align: center;
    position: relative;
  }

  .Notification_notification__close__3DDfq {
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    cursor: pointer;
  }
  
  .Notification_notification__2bsoV p {
    font-size: 1.125rem;
    font-weight: 900;
    margin: 0;
    color: #4E5D78;
    padding: 0 2rem;
  }

  p.Notification_notification-link__3jcNj {
    cursor: pointer;
    color: #8A94A6;
  }
  
  p.Notification_notification-link__3jcNj:hover{
    color: #323B4B;
  }
  .Notification_error__3AUaP {
    color: #D72F19;
  }
  
  .Notification_success__N2ljL {
    color: #3D6300;
  }
  

  .Notification_notification-buttons__3f0pI {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
}

@media(max-width: 65rem){
  .Notification_notification__2bsoV{
    width: 65%;
  }
}
@media (max-width: 40rem){
  .Notification_notification__2bsoV {
    width: 95%;
  }

  .Notification_notification-buttons__3f0pI {
    grid-gap: 1rem;
    gap: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.LoadingSpinner_spinner-wrapper__zUZSG {
    text-align: center;
    margin-top: 20rem;
    position: fixed;
    z-index: 18;
    width: calc(100% - 12.5rem); 
    left: 12.5rem;   
    display: flex;
    align-items: center;
    justify-content: center;}

.LoadingSpinner_loader__1iJqL {
    width: 48px;
    height: 48px;
    border: 3px solid #B1B5BA;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    -webkit-animation: LoadingSpinner_rotation__aaqQ_ 1s linear infinite;
            animation: LoadingSpinner_rotation__aaqQ_ 1s linear infinite;
  }
  .LoadingSpinner_loader__1iJqL::after {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: #637E1F;
  }
      
  @-webkit-keyframes LoadingSpinner_rotation__aaqQ_ {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
      
  @keyframes LoadingSpinner_rotation__aaqQ_ {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  } 

@media(max-width:65rem){
   .LoadingSpinner_spinner-wrapper__zUZSG {
    width: 100%;
    left: 0;
   }
}
div.MessageBox_message-wrapper__vlyBW {
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 21;
  }
  
  .MessageBox_message__3KvcR {
    height: auto;
    width: 40vw;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 2rem 0;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    text-align: center;
    position: relative;
  }

.MessageBox_message-header__xIVkE {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  height: 3.75rem;
  width: 100%;
  padding: 1.25rem;
}  

.MessageBox_message-title__CH0TC {
  flex-grow: 1;
  text-align: left;
  color: #2C3C05;
  font-weight: 900;
  font-size: 1.125rem;
}

.MessageBox_message-close__3wDeJ {
  cursor: pointer;
}

@media (max-width: 68rem){
  .MessageBox_message__3KvcR {
    width: 75vw;
  }
}

@media (max-width: 40rem){
  .MessageBox_message__3KvcR {
    width: 95%;
  }
}
.BackdropBlur_backdrop__2JBMB {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background: rgba(5, 16, 52, 0.3);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
}

.ErrorMessage_message__2Fiov {
    color: #4E5D78;
    font-size: 1.3rem;
    font-weight: 800;
    padding: 0 1rem;
}
.MainSidebar_main-sidebar__RoQls{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 12.5rem;
    background-color: #0E181D;
}
.MainSidebar_main-sidebar__logo__3kky2{
    display: inline-block;
    line-height: 45px;   
    margin: 35px 15%;
    width: 70%;
    height: 45px;
    text-align: center;
    color: white;
}

.MainSidebar_main-sidebar__logo__3kky2 img {
    width: 100%;
}

.MainSidebar_main-sidebar__items__-E2Fq{
    list-style: none;
    margin: 0;
    padding: 0;
}

.MainSidebar_main-sidebar__item__36mLC{
    height: 4rem;
    line-height: 4rem;
    margin: 0.5rem 0 0 0;
    width: 100%;
    vertical-align: middle;
}

.MainSidebar_main-sidebar__nav__1sSiU {
    height: 100%;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding-left: 0.8rem;

}

.MainSidebar_main-sidebar__item__36mLC div{
    display: inline-block;
    vertical-align: middle;
}

.MainSidebar_main-sidebar__item__36mLC img {
    vertical-align: middle;
    width: 1.3rem;
}

.MainSidebar_main-sidebar__item__36mLC label{
    color: white;
    font-size: 1rem;
    line-height: 27px;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 1rem;
}
.MainSidebar_main-sidebar__nav__1sSiU:hover,
.MainSidebar_main-sidebar__nav__1sSiU:active,
.MainSidebar_main-sidebar__nav__1sSiU.MainSidebar_active__24N9f {
    background-color: #2E3032;
}


.MainSidebar_main-sidebar__nav__1sSiU:hover label,
.MainSidebar_main-sidebar__nav__1sSiU:active label,
.MainSidebar_main-sidebar__nav__1sSiU.MainSidebar_active__24N9f label {
    color: #70921A;
}

@media (max-width: 65rem) {
    .MainSidebar_main-sidebar__RoQls {
        display: none;
    }
}


@media (max-width: 40rem){
    .MainSidebar_main-sidebar__RoQls {
        display: none;
    }
}

main{
    display: flex;
    flex-direction: column;
}

.NewFeatures_features__2G5df{
    display: flex;
    flex-direction: row;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    padding-right: 0.9rem;
    color: #2f2524;
    align-content: flex-start;
}

.NewFeatures_header__aY10y {
    display: flex;
    justify-content: space-between;
}

.NewFeatures_buttons__1o6dv {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* text-align: right; */
    align-self: flex-end;
    position: relative;
}

.NewFeatures_features-description__EjrIQ {
    padding: 2rem;
    grid-gap: 0;
    gap: 0;

}

.NewFeatures_features-description__EjrIQ a{
    color: #637E1F;
}
.NewFeatures_features-description__EjrIQ a:hover{
    color: #70921A;
}
.NewFeatures_features-description__1__1NzCb {
    font-weight: 700;
    font-size: 1.4rem;
}

.NewFeatures_features-description__1__1NzCb span {
    font-weight: 900;
}

.NewFeatures_features-description__2__1_2gl {
    font-size: 1.1rem;
}

.NewFeatures_features-description__3__1nq21 {
    text-align: left;
    list-style: disc;
}

.NewFeatures_features-page__3h2Pw {
    display: flex;
    flex: 1 1;
    overflow: hidden;}

.NewFeatures_implemented-features__2Sn-0 {
    width: 35%;
    background-color: #D7EEA1;
    margin: 1rem;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    margin-left: 0;
    margin-bottom: 0;
    overflow-y: auto;
    margin-top: 3.2rem;
}

.NewFeatures_implemented-features__title__2yLT7 {
    color: #2F353B;
    padding-left: 1rem;
}

@media(max-width: 75rem){
    .NewFeatures_implemented-features__2Sn-0{
        margin-top: 0;
    }
}

@media(max-width:45rem){

    main {
        overflow-y: auto;
    }
    .NewFeatures_features-page__3h2Pw{
        flex-wrap: wrap;
        flex: initial;
        overflow: visible;
        overflow: initial;
    }

    .NewFeatures_implemented-features__2Sn-0 {
        width: 100%;
        margin: 0.9rem;
    }

    .NewFeatures_add-feature__2Ym3x {
        position: relative;
        width: 80%;
        margin: auto;
        top: 1rem;
        right: 0;
    }
}


.MainHeader_top-menu__3b-Ra{
    height: 5rem;
    width: 100%;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    line-height: 5rem;
    padding-left:1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFF;
}
.MainHeader_top-menu__3b-Ra p {
    margin: 0;
    line-height: 5rem;
}

.MainHeader_top-menu__menu__3mWXQ {
    text-decoration: none;
    color: #B0B7C3;}

.MainHeader_top-menu__icons__3n-tv {
    display: flex;
    align-content: center;
    align-items: center;
}

.MainHeader_top-menu__icon__3-iHK {
    margin-left: 1.8rem;
}

.MainHeader_top-menu__icons__3n-tv img {
    display: block;
}

.MainHeader_top-menu__icons__3n-tv .MainHeader_top-menu__icons--username__1WgLz {
    height: 2.75rem;
    text-decoration: none;
    margin-left: 2.7rem;
    margin-right: 2.75rem;
    text-align: center;
    color: white;
    font-weight: 900;
    position: relative;
    cursor: pointer;
}


.MainHeader_top-menu__username__3aH_h {
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    border-radius: 50%;
    background-color: #637E1F;
}

.MainHeader_top-menu__logo__17GQt {
    display: none;
}

.MainHeader_top-menu__hemburger__cquJK {
    display: none;
}

@media (max-width: 65rem) {
    .MainHeader_top-menu__3b-Ra {
        background-color: #262829;
        padding-left: 0;
    }

    .MainHeader_top-menu__menu__3mWXQ {
        display: none;
    }

    .MainHeader_top-menu__logo__17GQt {
        display: inherit;
    }

    .MainHeader_top-menu__hemburger__cquJK {
        display: inherit;
        cursor: pointer;
    }
}




div.UserDetails_user-details__1QR_o {
    text-align: center;
    padding: 0rem 2rem 2rem 2rem;
    top: 5rem;
    cursor: default;
}

/*.user-details::after {
 content: "";
  position: absolute;
  top: -1.4rem;
  left: 70%;
  border-width: 0.7rem;
  border-style: solid;
  border-color:  transparent transparent  #D9D9D9 transparent;
}*/

.UserDetails_user-details__header__1wByO {
text-align: left;
line-height: 1.7rem;
font-size: 1.125rem;
font-weight: 900;
margin-top: 1.5rem;
margin-bottom: 1.25rem;
}

.UserDetails_user-details__1QR_o ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.UserDetails_user-detail__3wkxA {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    line-height: normal;
    margin-top: 1.25rem;
    line-height: 1.5rem;
}

.UserDetails_user-detail__separator__1zoNb {
    border: 1px solid #D9DDE3;
    margin-top: 1.25rem;

}

.UserDetails_logout__1jRsT {
    cursor: pointer;
    color: #1F3161;
    font-weight: 900;
    font-size: 0.94rem;
}


.Popup_popup__2m68L {
    background: #FFFFFF;
    position: absolute;
    top: 100%;
    right: 0%;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius:8px;
    padding: 1.5rem 0;
    width: -webkit-max-content;
    width: max-content;
    color:  #2F353B;
    z-index: 2;
  }

  .Popup_popup__2m68L:before {
    content: "";
    position: absolute;
    bottom: calc(100% - 0.5rem); /*i.e. half the height*/
    left: 65%;
    width: 1rem;
    height: 1rem;
    background: #FFFFFF;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-top:inherit;
    border-left:inherit;
  }
.MobileSidebar_main-sidebar__1CcDx{
    position: fixed;
    left: 0;
    top: 5rem;
    width: 100%;
    background-color: #0E181D;
    z-index: 4;
}

.MobileSidebar_main-sidebar__items__3hn4F{
    list-style: none;
    margin: 0;
    padding: 0;
}

.MobileSidebar_main-sidebar__item__1vnd-{
    height: 4rem;
    line-height: 4rem;
    margin: 0.5rem 0 0 0;
    width: 100%;
    vertical-align: middle;
}

.MobileSidebar_main-sidebar__nav__1akST {
    height: 100%;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding-left: 2.5rem;

}

.MobileSidebar_main-sidebar__item__1vnd- div{
    display: inline-block;
    vertical-align: middle;
}

.MobileSidebar_main-sidebar__item__1vnd- img {
    vertical-align: middle;

}

.MobileSidebar_main-sidebar__item__1vnd- label{
    color: white;
    font-size: 18px;
    line-height: 27px;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 1rem;
}
.MobileSidebar_main-sidebar__nav__1akST:hover,
.MobileSidebar_main-sidebar__nav__1akST:active,
.MobileSidebar_main-sidebar__nav__1akST .MobileSidebar_active__3zf-F {
    background-color: #2E3032;
}


.MobileSidebar_main-sidebar__nav__1akST:hover label,
.MobileSidebar_main-sidebar__nav__1akST:active label,
.MobileSidebar_main-sidebar__nav__1akST.MobileSidebar_active__3zf-F label {
    color: #70921A;
}

@media (min-width: 65rem) {
    .MobileSidebar_main-sidebar__1CcDx {
        display: none;
    }
}


.Button_btn__nCjwA {
    background: #70921A;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.75rem;
    border: none;
    color: #F7F8F9;
    cursor: pointer;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-family: inherit;
    font-size:  0.94rem;
    font-weight: 900;
}

.Button_btn__nCjwA:hover {
    color: #FFFFFF;
    background-color: #637E1F;
}

.Button_btn__nCjwA:active {
    background-color:  #49620E;
    color: #FFFFFF;
}

.Button_btn__nCjwA:disabled {
    background-color:  #EFF1F3;
    color: #8A94A6;
    cursor: auto;
}

.Button_delete__1d_ex {
    background-color: #8B1A1C;
}

.Button_delete__1d_ex:hover{
    background-color: #D72F19;
}

.Button_delete__1d_ex:active{
    background-color: #D72F19;
}

.Button_spinning__1u9qp {
    -webkit-animation: Button_spinner__2Woa0 3s linear infinite;
            animation: Button_spinner__2Woa0 3s linear infinite;
}

@-webkit-keyframes Button_spinner__2Woa0 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes Button_spinner__2Woa0 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}


.LoadingSpinnerButton_container__1QdN9 {
    width: 20px;
    height: 20px;

}

.LoadingSpinnerButton_loader__cuNcP {
    width: 18px;
    height: 18px;
    border: 2px solid #F7F8F9;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LoadingSpinnerButton_loader__cuNcP::after {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 2px solid transparent;
      -webkit-animation: LoadingSpinnerButton_rotation__38FB7 1s linear infinite;
              animation: LoadingSpinnerButton_rotation__38FB7 1s linear infinite;
      border-bottom-color: #B3D95A;
  }
      
  @-webkit-keyframes LoadingSpinnerButton_rotation__38FB7 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
      
  @keyframes LoadingSpinnerButton_rotation__38FB7 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  } 


.ButtonL_btn-l__3yLqx {
    min-height: 3rem;
}


.ButtonLOut_btn-l__lM0a5 {
    min-height: 3rem;
}


.ButtonOut_btn-o__2A8JX {
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.75rem;
    border: 1px solid #637E1F;  
    color: #637E1F;
    cursor: pointer;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 900;
}

.ButtonOut_btn-o__2A8JX:hover {
    color: #49620E;
    background-color: #F3FFD6;
    border: 1px solid #D7EEA1;
}

.ButtonOut_btn-o__2A8JX:active {
    background-color: #D7EEA1;
    color: #2C3C05;
    border: 1px solid #D7EEA1;
}

.ButtonOut_btn-o__2A8JX:disabled {
    background-color:  #EFF1F3;
    border-color: #B0B7C3;
    color:#B0B7C3;
    cursor: auto;
}


.ButtonM_btn-m__28svx {
    min-height: 2.25rem;
}



.Input_form-input__container__MCM5O {
    margin-bottom: 1.25rem;
    position: relative;
}

.Input_form-group__3ZKVD {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    height: 3rem;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    width: 100%;
}

.Input_form-group__3ZKVD label {
    display: block;
    color: #8A94A6;
    font-size: 0.75rem;
    text-align: left;
    height: 0.94rem;
}

.Input_form-group__3ZKVD input {
    color: #2F353B;
    border: none;
    font-size: 1rem;
    width: 100%;
    font-family: inherit;
    background-color: transparent;
}

.Input_form-group__3ZKVD input::-webkit-input-placeholder{
    color: #B0B7C3;

}

.Input_form-group__3ZKVD input:-ms-input-placeholder{
    color: #B0B7C3;

}

.Input_form-group__3ZKVD input::placeholder{
    color: #B0B7C3;

}

.Input_form-group__3ZKVD input:focus-visible {
    outline: none;
}

.Input_form-group__3ZKVD:focus-within {
    border-color: #70921A;
}

.Input_form-group__3ZKVD:focus-within input {
    caret-color: #70921A;
}

.Input_form-group__3ZKVD:hover {
    border-color: #70921A;
}

.Input_form-group__3ZKVD:focus-within label {
    color: #49620E;
}

.Input_form-group__3ZKVD.Input_readonly__38I0n {
    background-color: #FAFBFC;
    border: none;
}

.Input_form-group__3ZKVD.Input_readonly__38I0n input {
    color: #B0B7C3;
}
.Input_form-group__3ZKVD.Input_readonly__38I0n:focus-within label {
    color: #8A94A6;
}

.Input_form-group__3ZKVD.Input_readonly__38I0n input{
    cursor: default;
}

.Input_form-group__3ZKVD.Input_readonly__38I0n:hover {
    border: none;
}

.Input_form-group__3ZKVD.Input_readonly__38I0n:focus-within {
    border: 1px solid #D9DDE3;
}

.Input_form-input__container__MCM5O p {
    font-size: 0.75rem;
    padding-left: 0.75rem;
}

.Input_invalid-input__ikOHH .Input_form-group__3ZKVD{
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}
.Input_invalid-input__ikOHH:focus-within {
    border-color: #D72F19;
}

.Input_invalid-input__ikOHH input:focus {
    color: #8B1A1C;
}

.Input_invalid-input__ikOHH input {
    background-color: #FFEAE9;
}

.Input_invalid-input__ikOHH label {
    color: #8B1A1C;
}

.Input_invalid-input__ikOHH p {
    color: #8B1A1C;
    margin: 0;
    position: absolute;
}

.Input_form-group__3ZKVD input:disabled {
    border: none;
    color: #B0B7C3;
}

.Input_required-input__1c-iX {
    color: red;
}



.JiraCredentials_credentials-form__yaeZK {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.JiraCredentials_message__2StyQ {
    width: 40%;
    grid-gap: 0;
    gap: 0;
}

.JiraCredentials_input__CaSND {
    width: 80%;
}

.JiraCredentials_buttons__2jpTW {
    display: flex;
    justify-content: flex-end;
    width: 80%;
}
.Badge_badge__3yUx4 {
    height: 1.25rem;
    line-height: 1.25rem;
    border-radius: 64px;
    padding: 0rem 0.94rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
}
.Badge_regular__2LVUW {
    color: #5C5E61;
    background-color: #EFF1F3;
}

.Badge_error__3nbeR {
    color: #D72F19;
    background-color: #FFEAE9;
}

.Badge_success__35Pop {
    color: #3D6300;
    background-color: #E6F6EC;
}

.Badge_info__qWCAf {
    color: #57A2FA;
    background-color: #EBF4FF;
}

.Badge_warning__2_oGR {
    color: #E99D42;
    background-color: #F8F0D1;
}
.ImplementedFeature_implemented-feature__1-CM_ {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    padding: 1rem;
    margin: 1rem;
    position: relative;
}

.ImplementedFeature_status__3P_-E {
    width: 50%;
    right: 1rem;
    position: absolute;
}

.ImplementedFeature_id__4rAF2{
    color: #2F353B;
    margin: 0;
}

.ImplementedFeature_summary__1SA49 {
    color: #2F353B;

}

.ImplementedFeature_details__3BeTu{
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
    text-align: right;
}

.ListItem_list-item__1dru-{
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    width: 100%;
    height: 3.75rem;
    position: relative;
    color: #2F353B;
    overflow: hidden;
}

.ListItem_list-item__1dru- p {
    position: absolute;
    display: inline-block;
    margin: 0;
    line-height: 3.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ListItem_list-item__1dru- p:hover{
    /*width: max-content;
    background-color: white;
    z-index: 1;
    padding-right: 1rem;*/
}

@media (max-width: 65rem) {
    .ListItem_list-item__1dru-{
        height: 5.5rem;
    }

    .ListItem_list-item__1dru- p {
        line-height: normal;
        line-height: initial;
    }

}

@media (max-width: 40rem) {

    .ListItem_list-item__1dru-{
        height: 9rem;
    }

    
}
.FeaturesList_features-list__36AYV {
    width: 100%;
    padding: 0 0.9rem;
}


.FeaturesList_features-list__header__1kh5I p {
    display: flex;
    align-items: center;
    justify-content: center;
}
.FeaturesList_features__S0Z_p{
    display: flex;
    flex-direction: row;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    color: #2f2524;
    align-content: flex-start;
    height: calc(100% - 3.2rem);
}

.FeaturesList_id__ys4Gr {
    width: 2.5rem;
}

p.FeaturesList_title__3ZA1H{
    flex: 1 1;
    line-height: normal;
    line-height: initial;
    margin-top: 1rem;
    margin-bottom: 1rem;
    white-space: inherit;
    font-weight: 800;
    left: 10%;
    justify-content: flex-start;
}

p.FeaturesList_voted__1lcQN {
    left: 42%;
    width: 3%;
    text-align: center;
}

p.FeaturesList_my-votes__2q_1q{
    display: flex;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    width: 6rem;
    left: 48%;
    

}
.FeaturesList_feature__4Ss-n p.FeaturesList_my-votes__2q_1q{
    line-height: 2rem;
    border-radius: 12px;
    padding: 0.2rem 0.5rem;
    background-color: #EFF1F3;
}

.FeaturesList_completion__2Z6Su{
    width: 7rem;
    text-align: center;
}

.FeaturesList_features-list__header__1kh5I {
    position: relative;
    color: #8A94A6;
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 8px;
}

.FeaturesList_feature__4Ss-n{
    min-height: 3.75rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-between;
}

.FeaturesList_feature__4Ss-n,
.FeaturesList_features-list__header__1kh5I{
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.FeaturesList_feature__4Ss-n p {
    position: static;
}

.FeaturesList_feature__4Ss-n p.FeaturesList_my-votes__2q_1q{
    justify-content: space-between;
}

.FeaturesList_details__XJXzK{
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
    width: 6rem;
}

p.FeaturesList_details__XJXzK:hover {
    background-color: transparent;
}

.FeaturesList_arrow__1unKn{
    width: 1.5rem;
    cursor: pointer;
}

.FeaturesList_status-container__25hvz{
    width: 6rem;
    position: static;
}

.FeaturesList_feature__4Ss-n .FeaturesList_status__w60E6{
   white-space: nowrap;
}
.FeaturesList_watch__1Cp1m{
    width: 1.5rem;
}

.FeaturesList_feature__4Ss-n .FeaturesList_watch__1Cp1m{
    cursor: pointer;
    display: flex;
    align-items: center;
}


.FeaturesList_loading__2PHLr {
    cursor: wait;
}

@media(max-width: 82rem){
    .FeaturesList_feature__4Ss-n {
        min-height: 5rem;
    }

    .FeaturesList_feature__4Ss-n .FeaturesList_watch__1Cp1m {
        align-items: flex-end;
        height: 50%; 
        right: 1rem;
        left: auto;
        top: 0;
    }
    
    .FeaturesList_feature__4Ss-n .FeaturesList_details__XJXzK {
        bottom: 0;
        line-height: 3rem;
        right: 1rem;
        width: auto;
        left: auto;
    }
}

@media(max-width: 75rem){

    .FeaturesList_features-list__header__1kh5I{
        display: none;
    }

    .FeaturesList_features__S0Z_p {
        height: 100%;
    }

    .FeaturesList_feature__4Ss-n{
        position: relative;
        display: inline;
        display: initial;
        min-height: 9rem;
    }
    
    .FeaturesList_feature__4Ss-n p {
        line-height: normal;
        line-height: initial;
        position: absolute;
    }

    .FeaturesList_id__ys4Gr{
        top: 0.7rem;
        width: auto;
    }

    p.FeaturesList_title__3ZA1H {
        position: static;
        margin-top: 3rem;
        width: calc(100% - 14rem);
    }

    .FeaturesList_voted__1lcQN {
        left: auto;
        right: calc(3% + 19rem);
        top: 0.7rem;
        width: 5rem;
    }

    .FeaturesList_voted__1lcQN::before{
        content: 'Voted: ';
    }

    p.FeaturesList_my-votes__2q_1q {
        left: auto;
        right: calc(3% + 7rem);
        top: 0.7rem;
        justify-content: space-around;
        width: auto;
    }

    p.FeaturesList_my-votes__2q_1q::before{
        content: 'My Votes: ';
    }

    .FeaturesList_completion__2Z6Su{
        left: auto;
        right: 1rem;
        top: 2.5rem;
        width: auto;
    }

    .FeaturesList_completion__2Z6Su::before{
        content: 'Completion Date: ';
    }
    

    .FeaturesList_feature__4Ss-n .FeaturesList_watch__1Cp1m{
        top: auto;
        bottom: 2.5rem;
    }

    .FeaturesList_status-container__25hvz {
        position: absolute;
        top: 0.7rem;
        right: 1rem;
    }}

@media(max-width:30rem){

    .FeaturesList_feature__4Ss-n {
        min-height: 10.5rem;
    }

    .FeaturesList_voted__1lcQN {
        right: 13rem;
    }

    p.FeaturesList_my-votes__2q_1q {
        right: 1rem;
    }

    .FeaturesList_title__3ZA1H{
        width: 43%;
    }
    
    .FeaturesList_status-container__25hvz{
        top: 4.5rem;
    }
    
    p.FeaturesList_title__3ZA1H {
        margin-top: 4.5rem;
        width: calc(100% - 7rem);
    }


    
}

@media(max-width:24rem){

    .FeaturesList_voted__1lcQN {
        top: 2.6rem;
        right: auto;
        left: 1rem;
    }

    p.FeaturesList_my-votes__2q_1q {
        top: 2.6rem;
    }

    .FeaturesList_completion__2Z6Su{
        top: 0.7rem;
    }

    

}
.LoginModal_login-wrapper__nwtuk {
    position: relative;
    height: 100vh;
    top: 0;
    width: 100vw;
    left: 0;
    overflow: hidden;
}

.LoginModal_login-background__3r5ar {
    background-image: url(/static/media/BG.ac33c499.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10rem;
    gap: 10rem;
    position: absolute;
    left: 0;
    width: 47%;
    height: 100%;
    top: 0;
    color: #FFFFFF;
    font-size: 1.375rem;
}

.LoginModal_login-background__3r5ar img {
    margin-top: 20vh;
}

.LoginModal_login-right__15eej {
    background-color: #0E181D;
    position: absolute;
    height: 100vh;
    top: 0;
    width: 53%;
    left: 47%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.LoginModal_login-form__2ekD_ {
    width: 100%;
    max-width: 800px;
    padding: 0 12rem 0rem 12rem;
    overflow: auto;
}

.LoginModal_login-form__2ekD_ h1 {
    text-align: center;
    color: #D7E0F5;
    margin-bottom: 3rem;
    margin-left: -6rem;
    margin-right: -6rem;
}

.LoginModal_login-title__1RPwC {
    white-space: nowrap;
}

.LoginModal_logo-right__3KPCx{
    max-width: 22rem;
    max-height: 20rem;
}
@media (max-width: 65rem) {
    

    .LoginModal_login-background__3r5ar img {
        width: 18rem;
    }
    .LoginModal_login-form__2ekD_ {
        padding: 0 2rem 0 2rem;
    }

    .LoginModal_login-right__15eej > img {
        margin-top: 5vh;
        margin-bottom: 1vh;
        width: 8rem;
    }

    .LoginModal_login-form__2ekD_ h1 {
        margin-right: 0;
        margin-left: 0;
    }

}


@media (max-width: 40rem){
.LoginModal_login-wrapper__nwtuk {
    overflow: auto;
}

    .LoginModal_login-background__3r5ar{
        display: none;
    }

    .LoginModal_login-right__15eej{
        width: 100%;
        left: 0;
        height: auto;
        min-height: 100vh;
        padding-bottom: 3rem;
    }

    .LoginModal_login-form__2ekD_ {
        padding: 0 2rem 0 2rem;
    }

    .LoginModal_login-right__15eej > img {
        margin-top: 6vh;
        margin-bottom: 2vh;
        width: 10rem;
    }
}



.SubmitButton_login-button__1IKx3 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
    background-color: #70921A;
    color: #ffffff;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 0.94rem;
    font-weight: 900;
    font-family: inherit;
    border: none;
}

 
.LoginForm_login-form__1B4Nd input {
    width: 100%;
}

.LoginForm_login-form__line-remember-me__UXFvu {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
}

.LoginForm_login-form__remember-me__MXQeP label {
    color: #FFFFFF;
}

.LoginForm_sign-in-label__3s2lc {
    display: block;
    text-align: center;
    color: #F3FFD6;
    font-size: 0.875rem;
}

.LoginForm_login-form__line-remember-me__UXFvu p {
    color:  #637E1F;
    cursor: pointer;
    font-size: 0.94rem;
    font-weight: 900;
    text-decoration: none;
    vertical-align: middle;
}

.LoginForm_sign-in-label__3s2lc a:hover{
    color:  #70921A;
}

.LoginForm_sign-in-label__3s2lc a {
    color:  #637E1F;
    cursor: pointer;
    font-size: 0.81rem;
    font-weight: 900;
    text-decoration: none;
}

.LoginForm_error-message__10lQw {
    color: #D72F19;
    font-weight: 900;
}

.LoginForm_forgot-password__2WI9m{
    color: #637E1F;
    cursor: pointer;
}
.ModalDetails_modal__37dz- {
    display: inherit;
    position: absolute;
    top: 0;
    width: 62%;
    left: 38%;
    background-color: #F5F7FA;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    overflow: auto;
    height: calc(100% - 5rem);
    z-index: 21;
}

.ModalDetails_modal-back__3yMhz,
.ModalDetails_mobile-back__1VwOc {
    position: absolute;
    top: 2.5%;
    left: 33.3%;
    cursor: pointer;
}

.ModalDetails_modal-title__3pKER {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0E181D;
    height: 6.25rem;
    padding-left: 1.875rem;
}

.ModalDetails_modal-title__3pKER h2 {
    color: #FFFFFF;
    font-weight: 800;
    margin: 1.25rem 0 0.3rem 0;
}

.ModalDetails_modal-title__3pKER p {
    color: #B0B7C3
}

.ModalDetails_details-section__9_eyf {
    margin: 8.2rem 1.875rem 0 1.875rem;
}

.ModalDetails_mobile-close__2W_EZ {
    display: none;
    position: absolute;
    right: 1.5rem;
    top: 1.625rem;
    height: 0.94rem;
    width: 0.94rem;
    cursor: pointer;
}

.ModalDetails_mobile-back__1VwOc {
    display: none;
    left: 30%;
}

@media (max-width: 65rem) {

    .ModalDetails_modal-back__3yMhz {
        display: none;
    }

    .ModalDetails_mobile-back__1VwOc {
        display: inherit;
        left: 15.5%;
    }

    .ModalDetails_modal__37dz-{
        width: 80%;
        left: 20%;
    }


}

@media (max-width: 40rem) {
    .ModalDetails_modal__37dz- {
        width: 100%;
        left: 0;
    }

    .ModalDetails_details-section__9_eyf {
        margin: 7.19rem 0.94rem 0 0.94rem;
    }

    .ModalDetails_mobile-close__2W_EZ {
        display: block;
    }

}

@media(max-width: 25rem){
    .ModalDetails_modal-title__3pKER h2 {
        font-size: 1.3rem;
    }
}
.DetailsItem_detail-item__VkJEJ {
    height: 3.75rem;
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 9px;
    display: flex;
    align-items: center;
    grid-gap: 0.94rem;
    gap: 0.94rem;
    padding: 0 1.25rem;
    margin-bottom: 1.06rem;
    cursor: pointer;
}

.DetailsItem_title-closed__3hDHH {
    color: #4E5D78;

}

.DetailsItem_detail-item__open__3YkFV {
    background: #FFFFFF;
    border: 1px solid #B1B5BA;
    border-radius: 9px;
    margin-bottom: 1.06rem;
    box-shadow:  0px 4px 10px rgba(0, 0, 0, 0.15);;

}

.DetailsItem_detail-item__open-header__2dNuW {
    height: 3.75rem;
    background: #F5F7FA;
    border-radius: 9px 9px 0 0;
    display: flex;
    align-items: center;
    grid-gap: 0.94rem;
    gap: 0.94rem;
    padding: 0 1.25rem;
    cursor: pointer;
}

.DetailsItem_detail-item__open-header__2dNuW h3 {
    color: #637E1F;

}

.DetailsItem_btn-update__D4HBJ {
    margin-left: auto;
}

.DetailsItem_detail-item__body__1A45Q {
    padding:0.94rem 1.25rem 1.25rem 1.25rem;
    position: relative;
}

.DetailsItem_scroll__1sR4w{
    max-height: 28rem;
    overflow: auto;
}

.DetailsItem_closed__2r5ux {
    display: none;
}
.LoadingSpinnerButtonLight_container__KNPuU {
    width: 20px;
    height: 20px;

}

.LoadingSpinnerButtonLight_loader__2CnJ_ {
    width: 18px;
    height: 18px;
    border: 2px solid #B1B5BA;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LoadingSpinnerButtonLight_loader__2CnJ_::after {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 2px solid transparent;
      -webkit-animation: LoadingSpinnerButtonLight_rotation__fAjdS 1s linear infinite;
              animation: LoadingSpinnerButtonLight_rotation__fAjdS 1s linear infinite;
      border-bottom-color: #637E1F;
  }
      
  @-webkit-keyframes LoadingSpinnerButtonLight_rotation__fAjdS {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
      
  @keyframes LoadingSpinnerButtonLight_rotation__fAjdS {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  } 


.ButtonMOut_btn-m__JBOSj {
    min-height: 2.25rem;
}

.ButtonMOut_spinning__1Vg4o {
    -webkit-animation: ButtonMOut_spinner__Ijtjg 3s linear infinite;
            animation: ButtonMOut_spinner__Ijtjg 3s linear infinite;
}

.ButtonMOut_red__3fOP_ {
    color: #D72F19;
    border-color: #D72F19;
}

.ButtonMOut_red__3fOP_:hover {
    border-color: #F68686;
    background-color: #FFEAE9;
    color: #8B1A1C;
}

.ButtonMOut_red__3fOP_:active{
    border-color: #F68686;
    background-color: #F68686;
    color: #8B1A1C;
}

@-webkit-keyframes ButtonMOut_spinner__Ijtjg {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes ButtonMOut_spinner__Ijtjg {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}



.AddButton_add-button__2Luid {
    display: flex;
    align-items: center;
    grid-gap: 0.6rem;
    gap: 0.6rem;
    font-size: 0.94rem;
    font-weight: 900;
    color: #1F3161;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
}

.AddButton_border__3Y21_ {
    border: 1px solid #1F3161;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
}

.AddButton_img__1X4u8 {
    width: 1.5rem;
}

.AddButton_green__2_a1F {
    color: #70921A;
}

@media (max-width: 23rem){
    .AddButton_border__3Y21_ .AddButton_btn-text__3J1I0 {
        display: none;
    }
}

.TextField_text-area__wrapper__1VKEL {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 0.25rem 0.75rem 0.75rem 0.75rem;
}

.TextField_text-area__wrapper__1VKEL:hover{
    border-color: #70921A;
}

.TextField_text-area__wrapper__1VKEL label {
        display: block;
        color: #8A94A6;
        font-size: 0.75rem;
        line-height: 1.125rem;
}
.TextField_text-area__wrapper__1VKEL:focus-within label {
    color: #49620E;
}

.TextField_textarea__17N-Z {
    width: 100%;
    height: auto;
     resize: none;
     border: none;
     color: #2F353B;
    line-height: 1.5rem;
    font-size: 1rem;
    font-family: inherit;
    white-space: pre-wrap;
    min-height: 3rem;
}

.TextField_textarea__17N-Z:focus-visible {
    outline: none;
}

.TextField_required-input__3aYMM {
    color: red;
}

.TextField_invalid-input__1yNVU {
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}
.TextField_invalid-input__1yNVU:focus-within {
    border-color: #D72F19;
}

.TextField_invalid-input__1yNVU div:focus {
    color: #8B1A1C;
}

.TextField_invalid-input__1yNVU {
    background-color: #FFEAE9;
}

.TextField_invalid-input__1yNVU label {
    color: #8B1A1C;
}

.TextField_invalid-input__1yNVU {
    color: #8B1A1C;
}


.NewFeatureDetails_status__22vJd{
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.NewFeatureDetails_description__xo86v{
    color: #2F353B;
    font-size: 1.2rem;
    margin: 1.6rem;
}

.NewFeatureDetails_add-comments__B-sRK,
.NewFeatureDetails_save-comment__2rt5_ {
    display: flex;
    justify-content: flex-end;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 1rem;
}

.NewFeatureDetails_comment__2NfTm {
    margin: 1rem;
}

.NewFeatureDetails_attachments__3Kbd3 {
text-align: right;
}

.NewFeatureDetails_feature-attachment__2yfvD {
    width: 70%;
}

.NewFeatureDetails_comment-attachment__1xxWt {
    width: 50%;
}

.NewFeatureDetails_spinner__C8rEP {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
}
.TextButton_btn-cancel__3Fodu {
    font-size: 0.94rem;
    font-weight: 900;
    cursor: pointer;
    border: none;
    background: none;
    font-family: inherit;
}

.TextButton_green__30DNw {
    color: #637E1F;
}

.TextButton_blue__bHTD- {
    color: #1F3161;
}

.TextButton_pink__2F3DQ {
    color: #F68686;
}

.TextButton_gray__1fSEU {
    color: #8A94A6
}
.ImageUpload_upload-file__lQiV7 {
    margin-top: 1rem;
}


input[type="file"] {
    display: none;
}
.ImageUpload_custom-file-upload__yieuG {
    border: 1px solid #D9DDE3;
    display: inline-block;
    padding: 0 0.74rem;
    cursor: pointer;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #2F353B;
    height: 3rem;
    line-height: 3rem;

}

.ImageUpload_uploaded-files__2yZwI {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
}

.ImageUpload_uploaded-file__wJWO1 {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.ImageUpload_uploaded-file__wJWO1 span{
    max-width: 15rem;
    overflow: hidden;
}
.ImageUpload_uploaded-file__wJWO1 img {
    width: 5rem;
}

.FixedButtonsBar_fixed-bar__1DkaW {
    position: fixed;
    bottom: 0%;
    width: 62%;
    /*background: rgba(255, 255, 255, 0.1);*/
    border: 1px solid #D9DDE3;
    background-color: rgba(255, 255, 255, .9);
    height: 5rem;
    padding: 1.375rem 1.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 21;
    background-color: #F5F7FA;
}

@media(max-width: 65rem){
    .FixedButtonsBar_fixed-bar__1DkaW{
        width: 80%;
    }
}

@media (max-width: 40rem){
    .FixedButtonsBar_fixed-bar__1DkaW {
       width: 100%;
    }

}

  
  /* if backdrop support: very transparent and blurred 
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .fixed-bar {
        background: rgba(255, 255, 255, 0.1);
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);

    }
  }*/
.FixedRightBar_fixed-bar__KwX6K {
     left: 38%;
}

@media(max-width: 65rem){
    .FixedRightBar_fixed-bar__KwX6K{
        left: 20%;
    }
}

@media (max-width: 40rem){
     .FixedRightBar_fixed-bar__KwX6K {
         left: 0;
     }
 }
.FixedMiddleBar_fixed-bar__2pJj3 {
    left: 19%;
}

@media(max-width: 65rem){
    .FixedMiddleBar_fixed-bar__2pJj3{
        left: 10%;
    }
}

@media (max-width: 40rem){
    .FixedMiddleBar_fixed-bar__2pJj3 {
        left: 0;
    }
}
.ModalNew_modal__o7k3A {
    display: inherit;
    position: absolute;
    top: 10vh;
    width: 62%;
    left: 19%;
    height: calc(100% - 10vh - 5rem);
    z-index: 21;
}

.ModalNew_modal-title__NIQCz {
    /*position: absolute;
    top: 4.5vh;
    left: 19%;
    width: 62%;
    */
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;

}

.ModalNew_modal-title__main__31Glq {
    margin: 1rem 0rem;
}

.ModalNew_modal-title__close__A-9K4 {
    display: flex;
    align-items: center;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    font-weight: 900;
    font-size: 1.125rem;
}

.ModalNew_modal-title__close__A-9K4 img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.ModalNew_mobile-close__Mtp1A {
    display: none;
    align-items: center;
}

.ModalNew_mobile-close__Mtp1A img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.ModalNew_modal-white__2xY9X {
    background-color: #F5F7FA;
    border-radius: 16px 16px 0 0 ;
    padding: 1.875rem 1.125rem 1.875rem 3.125rem;
    box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);    z-index: 30;
    overflow: auto;
    height: calc(100% - 4.2rem);
}

.ModalNew_modal__o7k3A form {
    margin-right: 2rem;
}

@media(max-width: 65rem){
    .ModalNew_modal__o7k3A{
        width: 80%;
        left: 10%;
    }
}

@media (max-width: 40rem) {
    .ModalNew_modal__o7k3A {
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
    }

    .ModalNew_modal-title__NIQCz {
        background-color: #C1C7D0;
        color: #2F353B;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .ModalNew_modal-white__2xY9X {
        padding-left: 0.94rem;
        padding-right: 0.94rem;
        padding-top: 0;
        border-radius: 0;
    }

    .ModalNew_modal__o7k3A form {
        margin-right: 0;
    }
    
    .ModalNew_mobile-close__Mtp1A {
        display: flex;
    }

    .ModalNew_modal-title__close__A-9K4 {
        display: none;
    }
}


.ModelTitleClose_modal-title__2Vacq {
    height:4.2rem;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;

}

.ModelTitleClose_modal-title__main__2PAa- {
    margin: 1rem 0rem;
}

.ModelTitleClose_modal-title__close__3RUnT {
    display: flex;
    align-items: center;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    font-weight: 900;
    font-size: 1.125rem;
}

.ModelTitleClose_modal-title__close__3RUnT img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.ModelTitleClose_mobile-close___cx7E {
    display: none;
    align-items: center;
}

.ModelTitleClose_mobile-close___cx7E img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.AddNewFeature_header__272yK {
    color:  #2F353B;
}

.AddNewFeature_description__FRHtu {
    height: 15rem;
}

.AddNewFeature_success-message__2M-6k {
    color: #2C3C05;
    font-weight: 900;
    font-size: 1.4rem;
  
}

.PageNotFound_background__2c6ot {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    background-color: #EFF1F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
}

.PageNotFound_message-box__3hmx8 {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.12);
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PageNotFound_error__qREc6 {
    color:#2E3032;
    font-size: 1.5rem;
    margin: 0;
}

.PageNotFound_header__1ZJQK {
    color: #323B4B;
    font-weight: 800;
    font-size: 2rem;
    margin: 0;
}

.PageNotFound_message__3qXV4 {
    color: #4E5D78;
    font-size: 1.125rem;
    text-align: center;
    margin: 3rem 0;
}
.Statement_headers__28WfO {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Statement_statement__2oEDU {
    display: flex;
    overflow: hidden;
    padding-bottom: 0.625rem;
    flex: 1 1;
}
.Statement_right-side__3uEbf {
    width: 20%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.Statement_buttons-right-side__1ctI6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.Statement_add-payment__3gb01 {
    width: 100%;
    max-width: 15rem;
}

.Statement_school-details__oCJd3 div{
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
    gap: 0.75rem;
}

.Statement_school-details__oCJd3 div p {
    margin: 0.3rem 0;
}

.Statement_school-details__oCJd3 div a {
    margin: 0.3rem 0;
}

.Statement_statement-br__2KBIu{
    display: none;
}

@media(max-width: 65rem){
    .Statement_statement__2oEDU {
        flex-direction: column-reverse;
    }

    .Statement_right-side__3uEbf {
        width: 100%;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        grid-gap: 1rem;
        gap: 1rem;
        justify-content: flex-start;
        align-items: center;
    }

    .Statement_school-details__oCJd3 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 4.5rem;
        justify-content: flex-end;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
                column-gap: 1rem;
    }

    .Statement_school-details__oCJd3 h4 {
        margin: 0;
        height: 2.15rem;
        line-height: 2.15rem;
    }

    .Statement_buttons-right-side__1ctI6 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        flex-grow: 1;
        justify-content: flex-end;
        flex-direction: row;
    }

    
}

@media(max-width: 33rem){
    .Statement_statement-br__2KBIu{
        display: inherit;
    }
}
@media(max-width: 29rem){
    .Statement_school-details__oCJd3 {
        height: auto;
    }
}


main {
    display: flex;
    flex-direction: column;
}

.StatementViewItem_statement-view__wrapper__3aFx8 {
    flex: 1 1;
    padding-left: 1.625rem;
    overflow: hidden;
    padding-bottom: 0.625rem;
    display: flex;
    flex-direction: column;
}

.StatementViewItem_successful-payments__3eP_X {
    margin-right: 1rem;
    justify-content: flex-end;
}

.StatementViewItem_successful-payments__3eP_X label {
    color: #2F353B;
}

.StatementViewItem_statement-view__header__18Q4V {
    position: relative;
    height: 3.2rem;
    color: #8A94A6;
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 1.4rem;
    display: flex;
    align-items: center;
}

.StatementViewItem_statement-view__header__18Q4V p {
    position: absolute;
    display: flex;
    align-items: center;
    grid-gap: 0.2rem;
    gap: 0.2rem;

}
.StatementViewItem_statement-view__header__18Q4V p img{
    cursor: pointer;
}

.StatementViewItem_statement-view__list__2tE9V {
    display: flex;
    flex-direction: row;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    height: calc(100% - 3.2rem);
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    padding-right: 0.9rem;
    color: #2f2524;
    align-content: flex-start;
}

.StatementViewItem_view-item__28ZDD {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    width: 100%;
    height: 3.75rem;
    position: relative;
    color: #2F353B;
    display: flex;
    align-items: center;
}

.StatementViewItem_prev-balance-item__15z4F,
.StatementViewItem_running-balance-item__3kSa6{
    background-color: #D9DDE3;
}


.StatementViewItem_running-balance-item__3kSa6{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}

.StatementViewItem_view-item__28ZDD p {
    position: absolute;
    display: inline-block;
    margin: 0;
    line-height: 3.75rem;
    height: 3.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.StatementViewItem_value__2xhiT {
    left: 3%;
    font-weight: 800;
    width: 10%;
}

.StatementViewItem_balance__DsCbb {
    left: 15%;
    width: 11%;
}

.StatementViewItem_date__3fWzT {
    left: 28%;
    width: 11%;
}

.StatementViewItem_type__KnDhw {
    left: 41%;
    width: 15%;
    position: absolute;
}

.StatementViewItem_prev-balance__3_aQD{
    left: 41%;
    
}

.StatementViewItem_student__369Pm {
    left: 58%;
    width: 15%;
}

.StatementViewItem_details__1YFd_ {
    left: 74%;
    width: 11%;
}

.StatementViewItem_view-details__HpibL {
    right: 3%;
    max-width: 10%;
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
}


.StatementViewItem_spinner__PqTRm {
margin-top: 5rem;
}

@media(max-width: 65rem){
    .StatementViewItem_statement-view__wrapper__3aFx8 {
        width: auto;
        padding-left: 0.94rem;
    }
}

@media (max-width: 40rem) {
    .StatementViewItem_statement-view__header__18Q4V{
        display: none;
    }

    .StatementViewItem_view-item__28ZDD {
        height: 6rem;
        align-items: flex-end;
    }

    .StatementViewItem_value__2xhiT{
        left: 5%;
        width: 18%;
    }

    .StatementViewItem_balance__DsCbb{
        left: 25%;
        width: 20%;
    }

    .StatementViewItem_date__3fWzT{
        top: 5%;
        left: 5%;
        width: auto;
    }

    .StatementViewItem_type__KnDhw{
        bottom: 20%;
        width: 25%;
    }

    .StatementViewItem_details__1YFd_{
        top: 5%;
        left: 30%;
        width: auto;
    }

    .StatementViewItem_student__369Pm {
        top: 5%;
        left: 60%;
        width: 30%;
    }

    .StatementViewItem_view-details__HpibL{
        width: auto;
        max-width: none;
        right: 5%;
    }

    .StatementViewItem_prev-balance__3_aQD {
        top: 5%;
        left: 5%;
        width: auto;
    }
}

@media(max-width: 33rem){

    .StatementViewItem_view-item__28ZDD {
        height: 8rem;
    }

    .StatementViewItem_view-item__28ZDD p {
        height: 2rem;
        line-height: 2rem;
    }

    .StatementViewItem_type__KnDhw{
        left: 35%;
        top: 5.4rem;
        width: 30%;
    }

    .StatementViewItem_details__1YFd_{
        left: 35%;
        width: 60%;
        top: 3rem;
    }
    
    .StatementViewItem_value__2xhiT{
        width: 28%;
        top: 3rem;
    }

    .StatementViewItem_date__3fWzT {
        top: 1rem;
    }

    .StatementViewItem_balance__DsCbb{
        left: 5%;
        top: 5rem;
        width: 28%;
    }

    .StatementViewItem_student__369Pm {
        right: 5%;
        left: auto;
        width: auto;
        max-width: 45%;
        top: 1rem;
    }
}
.Checkbox_checkbox-wrapper__19DO0 {
    display: flex;
    align-items: center;
}

.Checkbox_checkbox__3v0HZ {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.25rem;
    height: 1.25rem;
    border-radius:4px;
    margin-right: 0.5rem;
    border: 1px solid #C8CCD2;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    outline: none;
    cursor: pointer;
  }

  .Checkbox_checkbox-wrapper__19DO0:hover .Checkbox_checkbox__3v0HZ {
    border: 1px solid #70921A;
  }

  .Checkbox_checked__3vdvY .Checkbox_checkbox__3v0HZ {
    border: 1px solid #70921A;
    background-color: #70921A;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABXSURBVHgB7dKxCQAgDETRjODGjuQIjuBIp6BIEBTxrEI+2JlHioh4tgIQ5FcNi+jxqMKKsGnsarvTpydsDEQaU4NlRZ8xteVEKWyDctiCpvYyfh6wZ6AK1xOqv/ak2v8AAAAASUVORK5CYII=);
    background-position: center;
    background-size: 100%;
    position: relative;
    font-family: sans-serif;
  }

  .Checkbox_checkbox-wrapper__19DO0.Checkbox_checked__3vdvY:hover .Checkbox_checkbox__3v0HZ {
    background: #49620E;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABXSURBVHgB7dKxCQAgDETRjODGjuQIjuBIp6BIEBTxrEI+2JlHioh4tgIQ5FcNi+jxqMKKsGnsarvTpydsDEQaU4NlRZ8xteVEKWyDctiCpvYyfh6wZ6AK1xOqv/ak2v8AAAAASUVORK5CYII=);
    background-position: center;
    background-size: 100%;
  }

  .Checkbox_checkbox-wrapper__19DO0.Checkbox_checked__3vdvY.Checkbox_small__287n0 input::before{
    font-size: 1rem;
  }

  .Checkbox_checkbox-wrapper__19DO0 .Checkbox_checkbox__3v0HZ:disabled,
  .Checkbox_checkbox-wrapper__19DO0.Checkbox_readonly__224Pa .Checkbox_checkbox__3v0HZ {
    border-color: #c0c0c0;
    background-color: #F7F8F9;
    cursor: default;
  }

  .Checkbox_checkbox-wrapper__19DO0.Checkbox_checked__3vdvY .Checkbox_checkbox__3v0HZ:disabled,
  .Checkbox_checkbox-wrapper__19DO0.Checkbox_readonly__224Pa.Checkbox_checked__3vdvY .Checkbox_checkbox__3v0HZ {
    background-color: #D7EEA1;
    border-color: #B3D95A;
    cursor: default;
  }


  .Checkbox_checkbox-wrapper__19DO0.Checkbox_checked__3vdvY:hover .Checkbox_checkbox__3v0HZ:disabled,
  .Checkbox_checked__3vdvY.Checkbox_readonly__224Pa:hover .Checkbox_checkbox__3v0HZ {
    background-color: #D7EEA1;
    border-color: #B3D95A;
    cursor: default;
  }
  
  .Checkbox_checkbox__3v0HZ:disabled + label,
  .Checkbox_readonly__224Pa .Checkbox_checkbox__3v0HZ + label {
    color: #c0c0c0;
  }
  
  .Checkbox_checkbox-wrapper__19DO0.Checkbox_small__287n0 .Checkbox_checkbox__3v0HZ {
    height: 1rem;
    width: 1rem;
}
.TaskListForm_tasks-page__1_uO9 {
    padding: 2rem 16%;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    overflow: auto;
    flex: 1 1;
}

.TaskListForm_header-line__kuK87 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TaskListForm_header__3oDfy {
    margin: 0;
    font-weight: 900;
    color: #2F353B;
    line-height: 2.25rem;
}

.TaskListForm_spinner-wrapper__uoceC {
    display: flex;
    padding-top: 10rem;
    position: absolute;
    margin: auto;
    width: -webkit-fill-available;;
}

.TaskListForm_other-dashboards___VExz{
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.TaskListForm_other-dashboards___VExz h3{
    font-weight: 900;
    color: #2F353B;
}

.TaskListForm_other-dashboards___VExz div{
    height: 1px;
    background-color: #D9DDE3;
    flex: 1 1;
}

@media(max-width: 86rem){
    .TaskListForm_tasks-page__1_uO9{
        padding-left: 2%;
        padding-right: 2%;
    }
}


.AddButton_plus__1AFXz {
    height: 18px;
    width: 18px;
}

.LoadingSpinnerMidlle_spinner__2XtgR {
    left: 0;
    width: 100%;
    position: static;
    margin-top: 0;
}
.TasksList_completed__2UeDD {
    display: flex;
    grid-gap: 0.94rem;
    gap: 0.94rem;
}

.TasksList_completed-text__3-OOW {
    margin: 0;
    color: #2F353B;
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.375rem;
}

.TasksList_action__OSNXx{
    height: 1.5rem;
    cursor: pointer;
    position: relative;
}


.TasksList_popup__39YiO {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 13.9rem;
    color:  #2F353B;
    z-index: 2;
    right: 0;
  }

  .TasksList_popup-item__1g_ZE{
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .TasksList_popup-item__1g_ZE:hover{
    background-color: #EFF1F3;
  }

  .TasksList_no-actions__1Q7cp{
    color: #2F353B;
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: default;
  }



.CircleProgressBar_wrap-circles__1ocJj {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .CircleProgressBar_circle__2ksY4 {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #D9DDE3;
    overflow: hidden;
  }
  
  .CircleProgressBar_circle__2ksY4.CircleProgressBar_per-75__3ILvj {
    background-image: conic-gradient(#70921A 75%, #D9DDE3 0);
  }
  
  .CircleProgressBar_circle__2ksY4 .CircleProgressBar_inner__U5FLG {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    background: #F5F7FA;
    border-radius: 50%;
    font-size: 1.85em;
    font-weight: 300;
  }
.DetailsItem_detail-item__s7p2N {
    border: 1px solid #B1B5BA;
    border-radius: 9px;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.04);
    background: #FFFFFF;
}
.DetailsItem_detail-item__header__Ub3rW {
    background: #F5F7FA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.25rem;
    cursor: pointer;
    border-radius: 9px;
}

.DetailsItem_detail-item__header-open__1FtsD {
    border-radius: 9px 9px 0 0;
}

.DetailsItem_header__1ip5- {
    display: flex;
    grid-gap: 0.94rem;
    gap: 0.94rem;
}

.DetailsItem_title__3_7yb {
    color: #2F353B;
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin: 0;
}

.DetailsItem_detail-item__body__1cTrU {
    padding: 1rem 1.25rem 1rem 1.25rem;
}
.TaskListTotals_container__3T7oo{
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
}

.TaskListTotals_totals__2tmp0 {
    display: flex;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    flex-wrap: wrap;
}

.TaskListTotals_name__oHOka {
    margin: 0;
    font-weight: 350;
    font-size: 0.875rem;
    color: #4E5D78;
    line-height: 1.375rem;
}

.TaskListTotals_value__1cArI {
    font-size: 1.125rem;
    font-weight: 800;
    color: #2F353B;
    line-height: 1.6875;
    margin: 0;
}

.TaskListTotals_bars__2jSYq {
    display: flex;
    flex-direction: column;
    width: 6.625rem !important;
}

.TaskListTotals_bar-total__2Vts8{
    display: flex;
    align-items: center;
    grid-gap: 0.2rem;
    gap: 0.2rem;
}

.TaskListTotals_total-numbers__65csl {
    display: flex;
    align-items: center;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    width: 3rem;
    justify-content: flex-end;

}

.TaskListTotals_amount__34-YN {
    color: #2F353B;
    font-weight: 800;
    font-size: 0.625rem;
    line-height: 1rem;
    margin: 0;
}

.TaskListTotals_compression__3W9d_ {
    color: #2F353B;
    font-weight: 350;
    font-size: 0.625rem;
    line-height: 1rem;
    margin: 0;
}

.TaskListTotals_bar__2Na_P {
    height: 0.5rem;
    margin: 0;
    border-radius: 1px;
    flex: 1 1;
}




.TaskList_list__3qMvM {
    display: flex;
    flex-direction: column;
}

.TaskList_footer__1VBiq{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.75rem;
}

.TaskListItem_task__2Wa0U {
    background-color: #F5F7FA;
    border-radius: 9px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
}

.TaskListItem_info__37LBH {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 70%;
    flex: 1 1;
}

.TaskListItem_title__3TEJk {
    font-weight: 800;
    font-size: 0.875rem;
    color: #2F353B;
    margin: 0;
    line-height: 1.375rem;
    width: 30%;
    cursor: pointer;
}

.TaskListItem_details__22lAU {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex: 1 1;
}

.TaskListItem_detail__3B1pL {
    font-weight: 350;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin: 0;
    color: #2F353B;
    flex: 1 1;
}



.TaskListItem_parentid__2nJlR {
    cursor: pointer;
    font-weight: 400;
    font-size: 0.75rem;
    color: #4E5D78;
}

.TaskListItem_parentid__2nJlR:hover{
    text-decoration: underline;
    color: #2F353B;
}

.TaskListItem_headers__1QyUT,
.TaskListItem_headers-admin__2vzkT{
  padding-right: 176px;
  padding-top: 0;
  background-color: #FFFFFF;
  border-radius: 0;
  padding-bottom: 0.75rem;
  z-index: 3;
}

.TaskListItem_headers-admin__2vzkT{
    padding-right: 1rem;
}

.TaskListItem_headers__1QyUT .TaskListItem_details__22lAU,
.TaskListItem_headers-admin__2vzkT .TaskListItem_details__22lAU {
    margin-left: calc(40% + 60px);
}

.TaskListItem_headers__1QyUT .TaskListItem_details__22lAU p,
.TaskListItem_headers-admin__2vzkT .TaskListItem_details__22lAU p{
    font-weight: 800;
    color: #4E5D78;
}

@media(max-width: 58rem){
    .TaskListItem_headers__1QyUT,
    .TaskListItem_headers-admin__2vzkT{
        display: none;
    }

    .TaskListItem_task__2Wa0U{
        align-items: flex-start;
    }

    .TaskListItem_title__3TEJk{
        width: calc(100% - 13rem);
    }

    .TaskListItem_info__37LBH{
        flex-wrap: wrap;
    }

    .TaskListItem_details__22lAU{
       order: 1;
    }
}

@media(max-width: 32.5rem){
    .TaskListItem_task__2Wa0U{
        position: relative;
        min-height: 6rem;
    }

    .TaskListItem_title__3TEJk {
        width: calc(100% - 3rem);
    }

    .TaskListItem_details__22lAU {
        flex-direction: column;
        width: calc(100% - 10rem);
        flex: none;
    }
    
}
.TaskActions_actions__30nbT {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-left: auto;
}

.TaskActions_action__1QMXE{
    height: 1.5rem;
    cursor: pointer;
    position: relative;
}


.TaskActions_loading__3gQHt {
    cursor: wait;
}

@media(max-width: 32.5rem){
    .TaskActions_actions__30nbT {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}
.Note_popup__11vt0 {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
    width: 16rem;
    color:  #2F353B;
    z-index: 4;
    right: 0;
    cursor: auto;
    cursor: initial;
  }

  .Note_header__3ru8I {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem;
  }

  .Note_title__fdJMD{
    color: #8A94A6;
    margin: 0;
    line-height: 1.5rem;
  }


  .Note_note__3i6cO {
    min-height: 8rem;
  }

  .Note_check__1Jbic {
    cursor: pointer;
  }
.NumbersPopup_popup__3a8Xz {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 14rem;
    color:  #2F353B;
    z-index: 4;
    right: 0;
    cursor: default;
  }

  .NumbersPopup_number-item__1r6V8 {
    padding: 0.25rem 0.75rem;
    position: relative;
  }

  .NumbersPopup_second__F4t_p{
    background-color: #F7F8F9;
  }

  .NumbersPopup_name__1FVwJ {
    color: #2F353B;
    font-size: 0.875rem;
    font-weight: 800;
    margin: 0;
    line-height: 1.375rem;
  }

  .NumbersPopup_number__1gPAk {
    color: #2F353B;
    font-size: 0.75rem;
    font-weight: 350;
    margin: 0;
    line-height: 1.25rem;
  }

  .NumbersPopup_templates-popup__2MJoK,
  .NumbersPopup_send-popup__176tb{
      background: #FFFFFF;
      position: absolute;
      border: 1px solid #D9DDE3;
      box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
      border-radius: 6px;
      padding: 0.25rem 0;
      width: 13.9rem;
      color:  #2F353B;
      z-index: 2;
      right: 14rem;
      top: 0;
  }

  .NumbersPopup_hide__4-D8k{
    display: none;
  }

  .NumbersPopup_loading__2Kutz{
    cursor: wait;
  }
.MorePopup_popup__3dyj3 {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: -webkit-max-content;
    width: max-content;
    color:  #2F353B;
    z-index: 4;
    right: 0;
  }

  .MorePopup_popup-item__RQ2i5,
  .MorePopup_popup-item__delete__1wDl_ {
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
  }

  .MorePopup_popup-item__RQ2i5:hover,
  .MorePopup_popup-item__delete__1wDl_:hover{
    background-color: #EFF1F3;
  }

  .MorePopup_popup-item__RQ2i5{
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .MorePopup_popup-item__RQ2i5 img {
    width: 1.5rem;
  }
  .MorePopup_popup-item__delete__1wDl_{
    color: #D72F19;
    border-top: 1px solid #D9DDE3;
  }

  .MorePopup_no-actions__1-6dq{
    color: #2F353B;
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
  }

  .MorePopup_snooze-popup__3zU3x {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 11rem;
    color:  #2F353B;
    z-index: 2;
    right: 101%;
    top: 0;
  }

  .MorePopup_loading__1RStD {
    cursor: wait;
}

.MorePopup_custom-date__3ThrR {
  margin:  0 0.5rem;
}
.ChargeTypes_popup-item__ftcem {
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
}

.ChargeTypes_popup-item__ftcem:hover{
  background-color: #EFF1F3;
}

.ChargeTypes_popup-list__gfmgh {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: -webkit-max-content;
    width: max-content;
    color:  #2F353B;
    z-index: 2;
    right: 101%;
    top: 0;
  }

  .ChargeTypes_left__1m6P-{
    left: auto;
    right: 101%;
  }
.PopupList_popup__2iPaa {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 14rem;
    color:  #2F353B;
    z-index: 2;
    right: 101%;
    cursor: default;
    max-height: 15rem;
    overflow-y: auto;
    top: 0;
  }

  .PopupList_loading__j5OCZ{
    cursor: wait;
  }
.PopupListItem_popup-item__1LTrh{
        padding: 0.25rem 0.75rem;
        position: relative;
}

.PopupListItem_second__2Ggb8{
    background-color: #F7F8F9;
  }
.Dashboard_load__3pBWL{
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    padding-top: 0.75rem;
}

.Dashboard_show-more__1MfTE {
    cursor: pointer;
    margin: 0;
    color: #323B4B;
    font-size: 0.875rem;
    font-weight: 350;
    display: flex;
    align-items: center;
    justify-content: center;}

    .Dashboard_list__1j-y9{
        display: flex;
        flex-direction: column;
        grid-gap: 0.75rem;
        gap: 0.75rem;
    }

    .Dashboard_sticky__3_f0P{
        position: -webkit-sticky;
        position: sticky;
        top: -2rem;
        z-index: 3;
    }

    .Dashboard_header__felL_{
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        height: 1.25rem;
        background-color: #FFFFFF;
        padding: 0.75rem 0;
        z-index: 3;
    }
    
    .Dashboard_line__3GYAE{
        height: 1px;
        background-color: #D9DDE3;
        flex: 1 1;
    }
    
    .Dashboard_view__Yn90f {
        margin: 0;
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        color: #2F353B;
        font-weight: 800;
        font-size: 0.75rem;
        line-height: 1.25rem;
        cursor: pointer;
    }
    
    .Dashboard_view__Yn90f img {
        width: 1rem;
    }

@media(max-width: 40rem){
    .Dashboard_sticky__3_f0P{
        position: static;
        position: initial;
    }
}


.LoginApplication_input__zAtyn,
.LoginApplication_button__2ez2u {
    opacity: 0;
}

.LoginApplication_backdrop__1KdaQ{
    display: flex;
    align-items: center;
}

div.LoginApplication_popup__2wkco{
    top: auto
}
.LoginApplication_spinner-wrapper__1nEC1 {
    display: flex;
    padding-top: 10rem;
    position: absolute;
    margin: auto;
    width: -webkit-fill-available;;
}
.SmallPopup_email-popup__1VeBZ{
    z-index: 50;
    position: fixed;
    border-radius: 9px;
    background-color: #FFFFFF;
    padding: 3rem 2rem 2rem 2rem;
    top: 50vh;
    box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);
    display: flex;
    flex-direction: column;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}

.SmallPopup_email-popup__close__1Qts2 {
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    cursor: pointer;

}
.AddTask_backdrop__3fpdg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 30; 
}

.AddTask_model__18tlL {
    height: auto;
    display: flex;
    flex-direction: column;
    position: static;
    
}

.AddTask_white__1uwLe{
    border-radius: 24px;
    padding: 1.875rem;
}

.AddTask_white__1uwLe form{
    margin: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.AddTask_date__3YhdZ {
    margin-bottom: 0;
    width: 49%;
}


.AddTask_dropdowns__3K4yY {
    display: flex;
    grid-gap: 0.625rem;
    gap: 0.625rem;
}

.AddTask_buttons__POwrP {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-end;
}

div.AddTask_name__3j3Er,
div.AddTask_type__muIRH {
    flex: 1 1;
}

div.AddTask_parent__2umH9 {
    flex: 1 1;
}

.AddTask_parent-wrapper__rVNlD {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 0.25rem 0.75rem 0.375rem 0.75rem 
}


.AddTask_parent-label__a2QGp {
    display: block;
    color: #8A94A6;
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin: 0;
}

.AddTask_connected-parent__16F6Q{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.AddTask_parentid__1VrQR {
    margin: 0;
    color: #2F353B;
    font-weight: 700;
}

.AddTask_parent-name__3B69d {
    margin: 0;
    color: #2F353B;
}

.AddTask_remove-parent__2Nq2g {
    margin-left: auto;
}

.AddTask_invalid__2UdbO {
    margin: 0;
    color: #D72F19;
    position: relative;
}

.AddTask_checkboxes-shul__2lJQW{
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

.AddTask_checkboxes__1arGc{
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

@media(max-width: 46rem){
    .AddTask_dropdowns__3K4yY{
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }

    .AddTask_date__3YhdZ,
    div.AddTask_campaign__1estZ,
    div.AddTask_shul__24Z39{
        width: 100%;
    }
}
.CustomSelectFreeText_search__3NwRw {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    height: 3rem;
    padding: 0.25rem 0.75rem;
    text-align: left;
}

.CustomSelectFreeText_search__3NwRw:hover,
.CustomSelectFreeText_search__3NwRw:focus-within  {
    border-color: #70921A;
}



ul.CustomSelectFreeText_options__ZVcNH {
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    list-style: none;
    margin-top: 4rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    padding-left: 0;
    padding-right: 0;
    left: 0;
    max-height: 25rem;
    overflow: auto;
  }
    
  ul.CustomSelectFreeText_options__ZVcNH li {
    background: #FFFFFF;
    height: 3.75rem;
    line-height: 3.75rem;
    cursor: pointer;
    padding: 0 1rem;
  }
  
  ul.CustomSelectFreeText_options__ZVcNH li:active,
  ul.CustomSelectFreeText_options__ZVcNH li:focus,
  ul.CustomSelectFreeText_options__ZVcNH li:hover,
  ul.CustomSelectFreeText_options__ZVcNH li.CustomSelectFreeText_hover-item__38Wr2 {
    background: #F4F4F4;;
  }

  ul.CustomSelectFreeText_options__ZVcNH li.CustomSelectFreeText_selected-item__16Rfp {
    background-color: #70921A;
    color: #FFFFFF;
  }
  
  ul.CustomSelectFreeText_options__ZVcNH li:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  ul.CustomSelectFreeText_options__ZVcNH li:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .CustomSelectFreeText_select-label__2knGX {
    display: block;
    color: #8A94A6;
    font-size: 0.75rem;
    text-align: left;
    height: 0.94rem;
}

.CustomSelectFreeText_select-input__PtTyM {
    background-color: transparent;
    border: none;
    width: 100%;
    padding-left: 0;
    color: #2F353B;
    font-size: 1rem;
    margin: 0;
    font-family: inherit;
}

.CustomSelectFreeText_select-input__PtTyM:focus {
    caret-color: #70921A;
}

.CustomSelectFreeText_select-input__PtTyM:focus-visible {
    outline: none;
}

.CustomSelectFreeText_required-input__tC-nA {
  color: red;
}

.CustomSelectFreeText_invalid-input__2rMsn.CustomSelectFreeText_search__3NwRw{
  background-color:  #FFEAE9;
  border-color: #D72F19;
  color: #8B1A1C;
}
.CustomSelectFreeText_invalid-input__2rMsn:focus-within {
  border-color: #D72F19;
}

.CustomSelectFreeText_invalid-input__2rMsn input:focus {
  color: #8B1A1C;
}

.CustomSelectFreeText_invalid-input__2rMsn input {
  background-color: #FFEAE9;
}

.CustomSelectFreeText_invalid-input__2rMsn label {
  color: #8B1A1C;
}

.CustomSelectFreeText_invalid-input__2rMsn p {
  color: #8B1A1C;
}

.DonorSearchBox_search__2FT_t {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 36px;
    height: 3rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.DonorSearchBox_search__2FT_t:hover {
    border-color: #B3D95A;
}

.DonorSearchBox_search__2FT_t:focus-within {
    border-color: #70921A;;
}

.DonorSearchBox_search__2FT_t input {
    border: none;
    font-family: inherit;
    font-size: 1rem;
    width: 90%;
}

.DonorSearchBox_search__2FT_t input::-webkit-input-placeholder {
    color: #8A94A6;
    font-size: 1rem;
}

.DonorSearchBox_search__2FT_t input:-ms-input-placeholder {
    color: #8A94A6;
    font-size: 1rem;
}

.DonorSearchBox_search__2FT_t input::placeholder {
    color: #8A94A6;
    font-size: 1rem;
}

.DonorSearchBox_search__2FT_t input:focus,
.DonorSearchBox_search__2FT_t input:focus-visible {
    border: none;
    outline: none;
}

.DonorSearchBox_search__2FT_t img {
    height: 1.3rem;
    width: 1.3rem;
    margin: 0.85rem;
}

.DonorSearchBox_donors__results__1jh7O {
    position: absolute;
    right: 1.125rem;
    color: #B0B7C3;
    font-size: 0.875rem;
}

ul.DonorSearchBox_options__3W-qu {
    background: #FFFFFF;
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    list-style: none;
    margin-top: 4rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    padding-left: 0;
    max-height: 22rem;
    overflow: auto;
  }
    
  .DonorSearchBox_no-results__3uLo_{
    color: #B0B7C3;
    text-align: center;
  }

.DonorSearchItem_search-donor__item__2beZ2 {
    background: #FFFFFF;
    min-height: 5.6rem;
    cursor: pointer;
    padding:0 0.94rem;
    position: relative;
  }

  .DonorSearchItem_search-donor__item__2beZ2 p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .DonorSearchItem_search-donor__item__2beZ2:active,
  .DonorSearchItem_search-donor__item__2beZ2.DonorSearchItem_active__XxTwj,
  .DonorSearchItem_search-donor__item__2beZ2:focus,
  .DonorSearchItem_search-donor__item__2beZ2:hover {
    background: #F4F4F4;;
  }

  .DonorSearchItem_search-donor__item__2beZ2:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  .DonorSearchItem_search-donor__item__2beZ2:last-of-type {
    border-radius: 0 0 8px 8px;
  }


.DonorSearchItem_search-donor__name__2YhP9 {
    color: #2F353B;
    font-weight: 900;
    height: 1.5rem;
    line-height: 1.5rem;
    margin:0.25rem 0;
    width: calc(100% - 5rem);
}

.DonorSearchItem_search-donor__id__2fSCq {
    position: absolute;
    right: 0.94rem;
    top: 0.15rem;
    height: 1.375rem;
    line-height: 1.375rem;
    font-size: 0.875rem;
    color: #8A94A6;
    margin: 0;

}

.DonorSearchItem_search-donor__phone__FYvkJ {
    color: #323B4B;
    font-size: 0.875rem;
    display: flex;
    grid-gap: 0.8125rem;
    gap: 0.8125rem;
    align-items: center;
}

.DonorSearchItem_search-donor__phone__FYvkJ p {
    margin: 0;
}

.DonorSearchItem_search-donor__address__2i9kX {
    line-height: 1.25rem;
    margin: 0;
    color: #8A94A6;
    font-size: 0.8125rem;
    font-weight: 900;
}

.DonorSearchItem_search-donor__hr__1841O {
    width: 90%;
}


@media((max-width: 60rem) and (min-width:40rem)){
  .DonorSearchItem_devider__3Guee {
    display: none;
  }

  .DonorSearchItem_search-donor__phone__FYvkJ {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
    align-items: flex-start;
  }
}

.LoadingSpinnerSearchBox_search-spinner__VxGKA{
    position: static;
    margin: 0;
    padding: 3rem 0;
    width: 100%;
}

.LoadingSpinnerSearchBox_spinner__1DmFM {
    height: 32px;
    width: 32px;
}

.LoadingSpinnerSearchBox_spinner__1DmFM::after{
    height: 25px;
    width: 25px;
}

.CustomSelect_select-wrapper__QQZ1D {
    display: flex;
    justify-content: center;
    width: 49%;
    height: 3rem;
    position: relative;
    text-align: left;
  }
  
  div.CustomSelect_select-container__30YRf {
    width: 100%;
    height: 100%;
  }
  
  .CustomSelect_select-button__13CVQ {
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 0 0.75rem;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-family: inherit;
    direction: ltr;
  }

  .CustomSelect_select-button__13CVQ:hover {
    border-color: #70921A;
}

.CustomSelect_select-button__13CVQ label {
    font-size: 0.75rem;
    color: #8A94A6;
    line-height: 1rem;
    position: absolute;
    top: 0.25rem;
}

.CustomSelect_select-button__13CVQ .CustomSelect_select__9Q6kT {
    background-color: #fff;
    border: none;
    height: 100%;
    width: 100%;
    padding-top: 1.5rem;
    padding-left: 0;
    color: #2F353B;
    line-height: 1.5rem;
    font-size: 1rem;
    margin: 0;
}

.CustomSelect_select-button__13CVQ .CustomSelect_select__9Q6kT:focus-visible {
    outline: none;
}

.CustomSelect_select-arrow__2mh-T {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;

}
  
  
  ul.CustomSelect_options__3VmGk {
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    display: none;
    list-style: none;
    margin-top: 0.6rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    max-height: 25rem;
    overflow: auto;
  }
  
  ul.CustomSelect_show__4Odai {
    display: block;
  }
  
  ul.CustomSelect_options__3VmGk li {
    background: #FFFFFF;
    height: 3.75rem;
    line-height: 3.75rem;
    cursor: pointer;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
overflow: hidden;
  }

  ul.CustomSelect_options__3VmGk li:first-of-type {
    border-radius: 8px 8px 0 0 ;
  }

  ul.CustomSelect_options__3VmGk li:last-of-type {
    border-radius: 0 0 8px 8px;
  }
  
  ul.CustomSelect_options__3VmGk li:active,
  ul.CustomSelect_options__3VmGk li:focus,
  ul.CustomSelect_options__3VmGk li:hover {
    background: #F4F4F4;;
  }

  ul.CustomSelect_options__3VmGk li[aria-selected="true"] {
    background-color: #70921A;
    color: #FFFFFF;
  }
  
  .CustomSelect_invalid-input__2nImd{
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}

.CustomSelect_invalid-input__2nImd:hover{
  border-color: #D72F19;
}

.CustomSelect_invalid-input__2nImd:focus-within {
    border-color: #D72F19;
}

.CustomSelect_invalid-input__2nImd input:focus {
    color: #8B1A1C;
}

.CustomSelect_invalid-input__2nImd .CustomSelect_select__9Q6kT {
    background-color: #FFEAE9;
}

.CustomSelect_invalid-input__2nImd label {
    color: #9b0709;
}


.SendReminder_buttons__1WYX4{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.SendReminder_top__dcFnp{
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.SendReminder_buttons__1WYX4 img{
    cursor: pointer;
}
.SendReminder_loading__1g8qw,
.SendReminder_loading__1g8qw img{
    cursor: wait;
}


.Tooltip_tooltip__1LGQK {
  position: relative;
  display: inline-block;
}

.Tooltip_tooltip__1LGQK .Tooltip_tooltip-text__1gTzS {
  opacity: 0;
  background-color: #F5F7FA;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: opacity 0.3s ease-out;
  width: -webkit-max-content;
  width: max-content;
  color: #2F353B;
  pointer-events: none; /* Prevent flickering */
  font-size: 0.85rem;
}

.Tooltip_top__3G0-u {
  bottom: 100%;
}

.Tooltip_bottom__2VWdR {
  top: 100%;
}

.Tooltip_tooltip-text__1gTzS::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #F5F7FA transparent transparent transparent;
}

.Tooltip_bottom__2VWdR::after {
  top: auto;
  bottom: 100%;
  border-color: transparent transparent #F5F7FA transparent;
}

.Tooltip_tooltip__1LGQK:hover .Tooltip_tooltip-text__1gTzS {
  opacity: 1;
}

.SidePopup_popup__heKIz {
    width: 21rem;
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    z-index: 1000;
    border-radius: 6px;
    -webkit-animation: SidePopup_show__3CMyZ 4.5s;
            animation: SidePopup_show__3CMyZ 4.5s;
    padding: 0.75rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: flex-start;
}

@-webkit-keyframes SidePopup_show__3CMyZ {
    0%   {right:-100%;}
       
    12% {right: 0;}

    88% {right: 0;}

    100% {right:-100%;}
    }

@keyframes SidePopup_show__3CMyZ {
    0%   {right:-100%;}
       
    12% {right: 0;}

    88% {right: 0;}

    100% {right:-100%;}
    }

.SidePopup_success__13I1V {
    background-color: #EBF4EC;
    border: 0.5px solid #D7EAD9;
    color: #00632F;
}

.SidePopup_error__1KjkH {
    background-color: #FFEAE9;
    border: 0.5px solid #F4D2D2;
    color: #8B1A1C;
}

.SidePopup_messages__31H2d {
    display: flex;
    flex-direction: column;
}

.SidePopup_header__28TkJ {
    margin: 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.4rem;
}

.SidePopup_message__3uuek {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.4rem;
}
.TaskDetails_backdrop__1Rphz{
    display: flex;
    align-items: center;
    justify-content: center;
}

.TaskDetails_wrapper__2szeE {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 60%;
    z-index: 21;
}

.TaskDetails_detail__sZVhA{
    border-radius: 24px;
    background-color: #F5F7FA;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12);
    padding: 1.875rem;
    overflow-y: auto;
    overflow-x: visible;
    position: relative;
    flex: 1 1;
}

.TaskDetails_form__30yoL{
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    position: relative;
}

.TaskDetails_header__3W7he{
    color: #2F353B;
    width: calc(100% - 21rem);
}

.TaskDetails_spinner__1ESWs{
    margin: 0;
    width: auto;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: static;
    position: initial;
}

.TaskDetails_save-note__3iNSl {
    display: flex;
    justify-content: flex-end;
}

.TaskDetails_details__iSVeX {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-start;
}

.TaskDetails_task-fields__2AZX_ {
    display: flex;
    flex-wrap: wrap;
    width: 54%;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-between;}

.TaskDetails_task-field__3GXCf {
    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    gap: 0.25rem;
}

.TaskDetails_field-key__Dc2Kr {
    color: #4E5D78;
    font-weight: 350;
    font-size: 0.75rem;
    margin: 0;
    line-height: 1.25rem;
}

.TaskDetails_field-value__3Pmz6{
    color: #2F353B;
    font-weight: 800;
    font-size: 0.75rem;
    margin: 0;
    line-height: 1.25rem;
}

.TaskDetails_task-queries__XGwxy{
    grid-gap: 1;
    gap: 1;
}

.TaskDetails_right__XVKAp,
.TaskDetails_left__2u35l {
    position: fixed;
    height: 3rem;
    width: 3rem;
    border: 1px solid #D9DDE3;
    border-radius: 50%;
    top: calc(50% + 0.65625rem);
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TaskDetails_right__XVKAp {
    right: calc(20% - 2rem);}

.TaskDetails_left__2u35l {
    left: calc(20% - 2rem);

}

.TaskDetails_line__1_5oQ{
    width: 1px;
    background-color: #D9DDE3;
    align-self: stretch;
}

@media(max-width: 65rem){
    .TaskDetails_wrapper__2szeE{
        width: 80%;
        height: 80%;
    }

    .TaskDetails_right__XVKAp{
        right: calc(10% - 2rem);
    }

    .TaskDetails_left__2u35l{
        left: calc(10% - 2rem);
    }
}

@media(max-width: 58rem){
    .TaskDetails_details__iSVeX{
        flex-direction: column;
    }

    .TaskDetails_task-fields__2AZX_{
        width: auto;
    }
}

@media(max-width: 53rem){
    .TaskDetails_header__3W7he{
        width: auto;
    }
}

@media(max-width: 27.5rem){
    .TaskDetails_detail__sZVhA{
        padding-right: 0.7rem;
        padding-left: 0.7rem;
    }
}
.TaskActions_actions__-yR30 {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.TaskActions_line__16WDy {
    flex: 1 1;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.TaskActions_call__1Ge3l,
.TaskActions_message__2uPB9 {
    width: 100%;
}

.TaskActions_action__HJIdQ {
    position: relative;
    flex: 1 1;
}

.TaskActions_more__23wih{
  display: flex;
  cursor: pointer;
  height: 100%;
}

.TaskActions_snooze-popup__3biWq {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 13.9rem;
    color:  #2F353B;
    z-index: 2;
    right: 0;
  }

  .TaskActions_popup-item__2whp0:hover{
    background-color: #EFF1F3;
  }

  .TaskActions_popup-item__2whp0{
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  @media(max-width: 53rem){
    .TaskActions_actions__-yR30{
      position: static;
      position: initial;
      order: -1;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
        align-self: flex-end;
    }
  }
.TaskQueries_queries__2Q_q_ {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex: 1 1;
}

.TaskQueries_captions__3Jvcg {
    display: flex;
    background-color: #EFF1F3;
    padding: 1px;
    border-radius: 6px;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    width: -webkit-max-content;
    width: max-content;
}

.TaskQueries_caption__22NxQ {
    color: #8A94A6;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1.25rem;
    margin: 0;
    padding: 0.125rem 0.75rem;
    cursor: pointer;
}

.TaskQueries_active__3cWnS {
    border: 1px solid #D9DDE3;
    color: #2F353B;
    font-weight: 800;
    background-color: #FFFFFF;
    border-radius: 4px;
}

.TaskQueries_rows__1zfPH {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    max-height: 10rem;
    overflow: auto;
}

.TaskQueries_headers__1bn_M,
.TaskQueries_row__2HQIn {
    display: flex;
    width: 100%;
}

.TaskQueries_header__1854X{
    color: #2F353B;
    font-size: 0.75rem;
    font-weight: 800;
    margin: 0;
    line-height: 1.25rem;
    flex: 1 1;
    cursor: pointer;
}

.TaskQueries_row-value__1dKAY{
    color: #2F353B;
    font-size: 0.75rem;
    font-weight: 350;
    margin: 0;
    line-height: 1.25rem;
    flex: 1 1;
}

@media(max-width: 65rem){
    .TaskQueries_rows__1zfPH{
        max-height: 16rem;
    }
}
.ChangesMessageBox_message-box-wrapper__2ISSv {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ChangesMessageBox_message-box__35cyF {
    width: 42%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    position: relative;
    z-index: 101;
    padding: 6.25rem 8.6rem 2.5rem 8.6rem;
}

.ChangesMessageBox_message-close__2QQOs {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.ChangesMessageBox_message-box__35cyF h1 {
    margin-bottom: 3.875rem;
    font-size: 2.25rem;
    font-weight: 800;
    text-align: center;
    color:#2F353B;
}


.ChangesMessageBox_buttons__2rN7t {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
}

.ChangesMessageBox_buttons__2rN7t button:first-of-type {
    font-size: 0.94rem;
    font-weight: 900;
    color:#1F3161;
    background: none;
    border: none;
    cursor: pointer;
}

@media(max-width: 65rem){
    .ChangesMessageBox_message-box__35cyF {
        width: 65%;
        padding-right: 5rem;
        padding-left: 5rem;
    }
}
@media (max-width: 40rem){
    .ChangesMessageBox_message-box__35cyF {
        width: 95%;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .ChangesMessageBox_buttons__2rN7t {
        grid-gap: 1rem;
        gap: 1rem;
        padding: 1rem;
    }
}


.ReminderDetails_first-row__3pSkh{
    display: flex;
    grid-gap: 2.5rem;
    gap: 2.5rem;
}

.ReminderDetails_id__1Dv0O,
.ReminderDetails_add-date__35sKm{
    color: #8A94A6;
    font-size: 0.875rem;
}

.ReminderDetails_fields__S5nCB{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.DonorsInfo_donor-name__29Oaz {
    color: #2F353B;
 } 

.DonorsInfo_donor-phone__1S5P0,
.DonorsInfo_donor-address__29QpL,
.DonorsInfo_donor-email__2wmaf,
.DonorsInfo_additional-addresses__3qBFO {
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
    gap: 0.75rem;
}

.DonorsInfo_additional-addresses__3qBFO {
    cursor: pointer;
    font-size: 0.94rem;
    font-weight: 900;
    color: #1F3161;
}

.DonorsInfo_buttons__2aTsP {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-end;
}

@media (max-width: 60rem){
    div.DonorsInfo_additional-addresses__list__Ou1A6 {
        width: 100% !important;
    }
}

@media (max-width: 40rem) {
    .DonorsInfo_donor-address__29QpL,
    .DonorsInfo_donor-email__2wmaf {
        flex-wrap: wrap;
        margin: 0.625rem 0;
    }

    .DonorsInfo_donor-address__29QpL p,
    .DonorsInfo_donor-email__2wmaf p {
        margin: 0;
    }

    .DonorsInfo_additional-addresses__3qBFO {
        width: 100%;
        margin-bottom: 0.625rem;
    }
    
    .DonorsInfo_additional-addresses__3qBFO p {
        margin-top: 0;
    }

}

.PageHeaders_header__FA4xQ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.6rem;
    flex-wrap: wrap;
}

.PageHeaders_header-title__16Yyy {
    margin-left: 0;
}

.PageHeaders_header__items__1r_9e {
    display: flex;
    flex-wrap: wrap-reverse;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    justify-content: flex-end;
    flex-grow: 1;
}

.PageHeaders_header__search__2OFph {
    background-color: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 36px;
    height: 2.25rem;
    width: 15rem;
    display: flex;
    align-items: center;
}

.PageHeaders_header__search__2OFph input {
    border: none;
    font-family: inherit;
}

.PageHeaders_header__search__2OFph input::-webkit-input-placeholder {
    color: #8A94A6;
    font-size: 0.875rem;
}

.PageHeaders_header__search__2OFph input:-ms-input-placeholder {
    color: #8A94A6;
    font-size: 0.875rem;
}

.PageHeaders_header__search__2OFph input::placeholder {
    color: #8A94A6;
    font-size: 0.875rem;
}

.PageHeaders_header__search__2OFph input:focus,
.PageHeaders_header__search__2OFph input:focus-visible {
    border: none;
    outline: none;
}

.PageHeaders_header__search__2OFph img {
    height: 1rem;
    width: 1rem;
    margin: 0.85rem;
}


.PageHeaders_header__add-btn__kB5E_ {
    margin-right: 2.75rem;
}
.PageHeaders_header__add-btn__kB5E_ img {
    height: 18px;
    width: 18px;
}

@media (max-width: 40rem){

    .PageHeaders_header__add-btn__kB5E_ {
        margin-right: 0.94rem;
    }
    .PageHeaders_header__add-btn__kB5E_ div {
        display: none;
    }
}


.FilterButton_filter__MENDf {
    height: 2.25rem;
    width: 2.5rem;
    background-color:  #D7E0F5;
    color: #1F3161;
    border-radius: 6px;
    margin: 0rem 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 40rem){
    .FilterButton_filter__MENDf{
        margin: 0 0.94rem;
    }
}
.SortBy_sort-button__2jx8m {
    background-color: #D7EEA1;
    height: 2.25rem;
    width: 2.5rem;
    color: #1F3161;
    border-radius: 6px;
    margin: 0rem 1.5rem 0rem 0rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.SortBy_sort-popup__aesvB {
    position: absolute;
    width: 500%;
    left: -250%;
    top: 2.5rem;
    border-radius: 9px;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    z-index: 1;
}

div.SortBy_wrapper__30Mbz {
    width: 100%;
}

.SortBy_sort__aoXRz {
    display: none;
}

@media (max-width: 65rem) {
    .SortBy_sort__aoXRz {
        display: inherit;
    }
}

@media(max-width: 40rem){
    .SortBy_sort-button__2jx8m{
        margin-right: 0.94rem;
    }
}
main {
    display: flex;
    flex-direction: column;
}

.RemindersList_reminder-wrapper__ten7R {
    margin-top: 1.3rem;
    flex: 1 1;
    padding-left: 1.625rem;
    margin-right: 1.8rem;
    overflow: hidden;
    padding-bottom: 0.625rem;
}

.RemindersList_reminder-list__header__R-U01 {
    position: relative;
    height: 3.2rem;
    color: #8A94A6;
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 1.4rem;
    display: flex;
    align-items: center;
}

.RemindersList_reminder-list__header__R-U01 p {
    position: absolute;
    display: flex;
    align-items: center;
    grid-gap: 0.2rem;
    gap: 0.2rem;
}

.RemindersList_reminder-list__header__R-U01 img {
    cursor: pointer;
}

.RemindersList_reminders-list__27t2U {
    display: flex;
    flex-direction: row;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    height: calc(100% - 3.2rem);
    width: 100%;
    overflow-y: scroll;
    flex-wrap: wrap;
    padding-right: 0.9rem;
    color: #2f2524;
    align-content: flex-start;
}

.RemindersList_id__KyYrI {
    left: 3%;
    font-weight: 800;
    width: 6%;
}

.RemindersList_donorid__21ZnQ {
    left: 11%;
    font-weight: 800;
    width: 6%;
}

.RemindersList_donor__V4ONE{
    left: 19%;
    width: 16%;
}

.RemindersList_name__1GhOt {
    left: 37%;
    width: 15%;

}

.RemindersList_type__2OIVM {
    left: 54%;
    width: 10%;

}

.RemindersList_date__3Hyk2 {
    left: 66%;
    width: 11%;

}


.RemindersList_status__2zdOJ{
    position: absolute;
    right: calc(3% + 10rem);
    width: 5rem;
    line-height: 1.25rem;
    margin: 1.25rem 0;
}

.RemindersList_details__hIEMU {
    right: 3%;
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
}

div p.RemindersList_details__hIEMU:hover,
div p.RemindersList_date__3Hyk2:hover{
    padding-right: 0;
}

.RemindersList_btn-load-more__1Dgha {
    background-color: #D9DDE3;
    border-radius: 9px;
    color: #5C5E61;
    width: 100%;
    height: 3.75rem;
    cursor: pointer;
    border: none;
    font-weight: 900;
    font-family: inherit;
}

div .RemindersList_mobile-status__3CXCL {
    display: none;
    position: absolute;
}

@media (max-width: 65rem) {
    .RemindersList_reminder-list__header__R-U01 {
        display: none;
    }

    .RemindersList_reminder-wrapper__ten7R {
        padding-right: 0.94rem;
        padding-left: 0.94rem;
        margin-right: 0;
        overflow: auto;
        bottom: 0;
    }

    .RemindersList_reminders-list__27t2U {
        overflow: hidden;
        height: auto;
        padding-right: 0;
    }

    div.RemindersList_reminder__RJAeq {
        height: 7rem;
    }

    .RemindersList_id__KyYrI {
        width: 28%;
        top: 0.7rem;
    }

    .RemindersList_id__KyYrI:before {
        content: "Reminder Id: ";
    }

    .RemindersList_donorid__21ZnQ {
        left: 3%;
        top: 2.4rem;
        width: 28%;
    }

    .RemindersList_donorid__21ZnQ:before {
        content: 'Donor Id: ';
    }

    .RemindersList_name__1GhOt {
        top: 4.1rem;
        left: 3%;
        width: 25%;
    }

    .RemindersList_date__3Hyk2 {
        left: 27%;
        top: 0.7rem;
        width: 20%;
        text-align: left;
    }


    .RemindersList_type__2OIVM {
        left: 27%;
        top: 2.4rem;
        width: 20%;
        text-align: left;
    } 

    .RemindersList_details__hIEMU {
        left: auto;
        bottom: 0.7rem;
    }
}

@media (max-width: 40rem) {

    div.RemindersList_reminder__RJAeq {
        height: 8.5rem;
    }

    .RemindersList_id__KyYrI {
        width: 53%;
        top: 0.5rem;
    }

    div p.RemindersList_donorid__21ZnQ {
        display: none;
    }

    .RemindersList_name__1GhOt {
        width: 90%;
        top: 2.2rem;
    }

    div p.RemindersList_date__3Hyk2 {
        top: 3.9rem;
        left: 3%;
        text-align: left;
        width: 53%;
    }



    div p.RemindersList_type__2OIVM {
        display: none;
    }

    .RemindersList_details__hIEMU {
        bottom: 0.5rem;
    }

    label.RemindersList_mobile-status__3CXCL {
        display: inherit;
        top: 6rem;
        left: 3%;
    }
}
.SearchDonor_donor-search__wrapper__2y4eO {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.SearchDonor_donor-search__2iFMj {
    width: 20rem;
}

.SearchDonor_filter__2TORW {
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.625rem;
}
@media(max-width: 27rem){
    .SearchDonor_donor-search__wrapper__2y4eO{
        flex-wrap: wrap-reverse;
    }
}


.FilterPayments_filter-form__32XP1{
    text-align: left;
    padding: 0 2.5rem;
    width: 100%;
    overflow: auto;
    height: 100%;
}

.FilterPayments_select__11zt0{
    display: inline-block;
    width: 80%;
}

.FilterPayments_date-range__header__3alwK {
    color: #323B4B;
    font-weight: 900;
    font-size: 1rem;
    margin: 0.625rem 0;
}

.FilterPayments_date-range__1md7m {
    display: flex;
    grid-gap: 0.625rem;
    gap: 0.625rem;
}

.FilterPayments_date-range__input__1sW6G {
    flex-grow: 1;
    margin-bottom: 0.625rem;
}



.FilterPayments_buttons__9epUt{
    text-align: right;
}

.FilterPayments_selected-donors__Q9hcD{
    display: flex;
    justify-content: space-between;
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    padding: 0 1rem;
    margin-bottom: 0.625rem;
}

.FilterPayments_selected-donors__Q9hcD p {
    margin: 0.5rem 0;
}

.FilterPayments_selected-donors__Q9hcD img{
    width: 1rem;
    cursor: pointer;
}

.FilterPayments_name__3JR5m,
.FilterPayments_type__3kaex,
.FilterPayments_reminderids__3YYUq,
.FilterPayments_description__2kFuP{
    margin-bottom: 0.625rem;
}

.FilterPayments_status__31W3d{
     width: 100%;
}
@media(max-width:25rem){
    .FilterPayments_date-range__1md7m{
        grid-gap: 0;
        gap: 0;
        flex-direction: column;
    }
}
.MultiSelect_c-multi-select-dropdown__3QamC {
    position: relative;
    font-size: 1rem;
}

.MultiSelect_multi-select__input__3BJyZ {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 1.25rem 0.75rem 0.25rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: rem;
    margin-bottom: 0.625rem;
}

.MultiSelect_multi-select__input__3BJyZ:hover {
    border-color: #70921A;
}
.MultiSelect_multi-select__label__3xDm0 {
    font-size: 0.75rem;
    color: #8A94A6;
    line-height: 1rem;
    position: absolute;
    top: 0.25rem;

}    


.MultiSelect_multi-select__selected__TkWL1 {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

.MultiSelect_multi-select__selected-option__3T0zq {
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 0 2px;
    min-height: 1.3rem;
}

.MultiSelect_multi-select__selected-option__3T0zq p {
    font-size: 0.85rem;
    padding: 0.1875rem 0.1875rem 0.1875rem 0.375rem;
    margin: 0;
}


.MultiSelect_multi-select__selected-option__3T0zq div {
    cursor: pointer;
    padding: 0 0.25rem;
    height: 100%;
    align-items: center;
    border-radius: 2px;
    display: flex;
}

.MultiSelect_multi-select__selected-option__3T0zq img {
    width: 12px;
}

.MultiSelect_multi-select__arrow__3kC7a {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
}

.MultiSelect_multi-select__selected-option__3T0zq div:hover {
    background-color: rgb(255, 189, 173);
}

.MultiSelect_multi-select__input__3BJyZ input {
    /*flex:1;*/
    border: none;
    background: none;
    font-size: 1rem;
    height: 1.3rem;
    width: 40%;
}

.MultiSelect_multi-select__input__3BJyZ input:focus-visible {
    outline: none;
}

.MultiSelect_multi-select__clear__lzCve {
    width: 12px;
}



.MultiSelect_multi-select__options__3kHmu {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    list-style: none;
    padding: 5px 0px;
    z-index: 2;
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    background-color: #FFFFFF;
    max-height: 20rem;
    overflow: auto;
}

.MultiSelect_multi-select__option__2uAVw {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    background: #FFFFFF;
    height: 3.75rem;
    line-height: 3.75rem;
    cursor: pointer;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    overflow: hidden;

}

.MultiSelect_multi-select__option__2uAVw:hover,
.MultiSelect_multi-select__option__2uAVw.MultiSelect_active__Izab7 {
    background-color: #eee;
}

.MultiSelect_invalid-input__3Ofow.MultiSelect_multi-select__input__3BJyZ{
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}
.MultiSelect_invalid-input__3Ofow:focus-within {
    border-color: #D72F19;
}

.MultiSelect_invalid-input__3Ofow input:focus {
    color: #8B1A1C;
}

.MultiSelect_invalid-input__3Ofow input {
    background-color: #FFEAE9;
}

.MultiSelect_invalid-input__3Ofow label {
    color: #8B1A1C;
}

.MultiSelect_invalid-input__3Ofow p {
    color: #8B1A1C;
    margin: 0;
    position: absolute;
}




.FilterModal_filter-modal__1VWqW {
    grid-gap: 0;
    gap: 0;
    grid-gap: 0;
    height: 80%;
}

.FilterModal_filter-header__3jCKJ {
font-size: 1.125rem;
font-weight: 900;
color: #2C3C05;
}

.FilterModal_filter-buttons__qDUrm {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.FilterModal_filter-cancel__3z4-v {
    position: absolute;
    left: 5%;
}

@media (max-width: 40rem){
    .FilterModal_filter-cancel__3z4-v {
        display: none;
    }
}

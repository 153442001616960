.form-input__container {
    margin-bottom: 1.25rem;
    position: relative;
}

.form-group {
    background: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    height: 3rem;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    width: 100%;
}

.form-group label {
    display: block;
    color: #8A94A6;
    font-size: 0.75rem;
    text-align: left;
    height: 0.94rem;
}

.form-group input {
    color: #2F353B;
    border: none;
    font-size: 1rem;
    width: 100%;
    font-family: inherit;
    background-color: transparent;
}

.form-group input::placeholder{
    color: #B0B7C3;

}

.form-group input:focus-visible {
    outline: none;
}

.form-group:focus-within {
    border-color: #70921A;
}

.form-group:focus-within input {
    caret-color: #70921A;
}

.form-group:hover {
    border-color: #70921A;
}

.form-group:focus-within label {
    color: #49620E;
}

.form-group.readonly {
    background-color: #FAFBFC;
    border: none;
}

.form-group.readonly input {
    color: #B0B7C3;
}
.form-group.readonly:focus-within label {
    color: #8A94A6;
}

.form-group.readonly input{
    cursor: default;
}

.form-group.readonly:hover {
    border: none;
}

.form-group.readonly:focus-within {
    border: 1px solid #D9DDE3;
}

.form-input__container p {
    font-size: 0.75rem;
    padding-left: 0.75rem;
}

.invalid-input .form-group{
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}
.invalid-input:focus-within {
    border-color: #D72F19;
}

.invalid-input input:focus {
    color: #8B1A1C;
}

.invalid-input input {
    background-color: #FFEAE9;
}

.invalid-input label {
    color: #8B1A1C;
}

.invalid-input p {
    color: #8B1A1C;
    margin: 0;
    position: absolute;
}

.form-group input:disabled {
    border: none;
    color: #B0B7C3;
}

.required-input {
    color: red;
}


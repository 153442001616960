.backdrop{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 60%;
    z-index: 21;
}

.detail{
    border-radius: 24px;
    background-color: #F5F7FA;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12);
    padding: 1.875rem;
    overflow-y: auto;
    overflow-x: visible;
    position: relative;
    flex: 1;
}

.form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
}

.header{
    color: #2F353B;
    width: calc(100% - 21rem);
}

.spinner{
    margin: 0;
    width: auto;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: initial;
}

.save-note {
    display: flex;
    justify-content: flex-end;
}

.details {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.task-fields {
    display: flex;
    flex-wrap: wrap;
    width: 54%;
    gap: 2rem;
    justify-content: space-between;}

.task-field {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.field-key {
    color: #4E5D78;
    font-weight: 350;
    font-size: 0.75rem;
    margin: 0;
    line-height: 1.25rem;
}

.field-value{
    color: #2F353B;
    font-weight: 800;
    font-size: 0.75rem;
    margin: 0;
    line-height: 1.25rem;
}

.task-queries{
    gap: 1;
}

.right,
.left {
    position: fixed;
    height: 3rem;
    width: 3rem;
    border: 1px solid #D9DDE3;
    border-radius: 50%;
    top: calc(50% + 0.65625rem);
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right {
    right: calc(20% - 2rem);}

.left {
    left: calc(20% - 2rem);

}

.line{
    width: 1px;
    background-color: #D9DDE3;
    align-self: stretch;
}

@media(max-width: 65rem){
    .wrapper{
        width: 80%;
        height: 80%;
    }

    .right{
        right: calc(10% - 2rem);
    }

    .left{
        left: calc(10% - 2rem);
    }
}

@media(max-width: 58rem){
    .details{
        flex-direction: column;
    }

    .task-fields{
        width: auto;
    }
}

@media(max-width: 53rem){
    .header{
        width: auto;
    }
}

@media(max-width: 27.5rem){
    .detail{
        padding-right: 0.7rem;
        padding-left: 0.7rem;
    }
}
.list-item{
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    width: 100%;
    height: 3.75rem;
    position: relative;
    color: #2F353B;
    overflow: hidden;
}

.list-item p {
    position: absolute;
    display: inline-block;
    margin: 0;
    line-height: 3.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.list-item p:hover{
    /*width: max-content;
    background-color: white;
    z-index: 1;
    padding-right: 1rem;*/
}

@media (max-width: 65rem) {
    .list-item{
        height: 5.5rem;
    }

    .list-item p {
        line-height: initial;
    }

}

@media (max-width: 40rem) {

    .list-item{
        height: 9rem;
    }

    
}
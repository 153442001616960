.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.6rem;
    flex-wrap: wrap;
}

.header-title {
    margin-left: 0;
}

.header__items {
    display: flex;
    flex-wrap: wrap-reverse;
    row-gap: 1rem;
    justify-content: flex-end;
    flex-grow: 1;
}

.header__search {
    background-color: #FFFFFF;
    border: 1px solid #D9DDE3;
    border-radius: 36px;
    height: 2.25rem;
    width: 15rem;
    display: flex;
    align-items: center;
}

.header__search input {
    border: none;
    font-family: inherit;
}

.header__search input::placeholder {
    color: #8A94A6;
    font-size: 0.875rem;
}

.header__search input:focus,
.header__search input:focus-visible {
    border: none;
    outline: none;
}

.header__search img {
    height: 1rem;
    width: 1rem;
    margin: 0.85rem;
}


.header__add-btn {
    margin-right: 2.75rem;
}
.header__add-btn img {
    height: 18px;
    width: 18px;
}

@media (max-width: 40rem){

    .header__add-btn {
        margin-right: 0.94rem;
    }
    .header__add-btn div {
        display: none;
    }
}


.implemented-feature {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 9px;
    padding: 1rem;
    margin: 1rem;
    position: relative;
}

.status {
    width: 50%;
    right: 1rem;
    position: absolute;
}

.id{
    color: #2F353B;
    margin: 0;
}

.summary {
    color: #2F353B;

}

.details{
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
    text-align: right;
}


.popup {
    background: #FFFFFF;
    position: absolute;
    top: 100%;
    right: 0%;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius:8px;
    padding: 1.5rem 0;
    width: max-content;
    color:  #2F353B;
    z-index: 2;
  }

  .popup:before {
    content: "";
    position: absolute;
    bottom: calc(100% - 0.5rem); /*i.e. half the height*/
    left: 65%;
    width: 1rem;
    height: 1rem;
    background: #FFFFFF;
    transform: rotate(45deg);
    border-top:inherit;
    border-left:inherit;
  }
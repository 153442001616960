.search-spinner{
    position: static;
    margin: 0;
    padding: 3rem 0;
    width: 100%;
}

.spinner {
    height: 32px;
    width: 32px;
}

.spinner::after{
    height: 25px;
    width: 25px;
}

.actions {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.line {
    flex: 1;
    display: flex;
    gap: 0.5rem;
}

.call,
.message {
    width: 100%;
}

.action {
    position: relative;
    flex: 1;
}

.more{
  display: flex;
  cursor: pointer;
  height: 100%;
}

.snooze-popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 13.9rem;
    color:  #2F353B;
    z-index: 2;
    right: 0;
  }

  .popup-item:hover{
    background-color: #EFF1F3;
  }

  .popup-item{
    margin: 0;
    padding: 0.25rem 0.875rem;
    line-height: 1.5rem;
    color: #2F353B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  @media(max-width: 53rem){
    .actions{
      position: initial;
      order: -1;
      max-width: fit-content;
        align-self: flex-end;
    }
  }
.popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0;
    width: 14rem;
    color:  #2F353B;
    z-index: 4;
    right: 0;
    cursor: default;
  }

  .number-item {
    padding: 0.25rem 0.75rem;
    position: relative;
  }

  .second{
    background-color: #F7F8F9;
  }

  .name {
    color: #2F353B;
    font-size: 0.875rem;
    font-weight: 800;
    margin: 0;
    line-height: 1.375rem;
  }

  .number {
    color: #2F353B;
    font-size: 0.75rem;
    font-weight: 350;
    margin: 0;
    line-height: 1.25rem;
  }

  .templates-popup,
  .send-popup{
      background: #FFFFFF;
      position: absolute;
      border: 1px solid #D9DDE3;
      box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
      border-radius: 6px;
      padding: 0.25rem 0;
      width: 13.9rem;
      color:  #2F353B;
      z-index: 2;
      right: 14rem;
      top: 0;
  }

  .hide{
    display: none;
  }

  .loading{
    cursor: wait;
  }
.features-list {
    width: 100%;
    padding: 0 0.9rem;
}


.features-list__header p {
    display: flex;
    align-items: center;
    justify-content: center;
}
.features{
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    color: #2f2524;
    align-content: flex-start;
    height: calc(100% - 3.2rem);
}

.id {
    width: 2.5rem;
}

p.title{
    flex: 1;
    line-height: initial;
    margin-top: 1rem;
    margin-bottom: 1rem;
    white-space: inherit;
    font-weight: 800;
    left: 10%;
    justify-content: flex-start;
}

p.voted {
    left: 42%;
    width: 3%;
    text-align: center;
}

p.my-votes{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: 6rem;
    left: 48%;
    

}
.feature p.my-votes{
    line-height: 2rem;
    border-radius: 12px;
    padding: 0.2rem 0.5rem;
    background-color: #EFF1F3;
}

.completion{
    width: 7rem;
    text-align: center;
}

.features-list__header {
    position: relative;
    color: #8A94A6;
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 8px;
}

.feature{
    min-height: 3.75rem;
    height: fit-content;
    justify-content: space-between;
}

.feature,
.features-list__header{
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature p {
    position: static;
}

.feature p.my-votes{
    justify-content: space-between;
}

.details{
    color: #1F3161;
    cursor: pointer;
    font-weight: 900;
    width: 6rem;
}

p.details:hover {
    background-color: transparent;
}

.arrow{
    width: 1.5rem;
    cursor: pointer;
}

.status-container{
    width: 6rem;
    position: static;
}

.feature .status{
   white-space: nowrap;
}
.watch{
    width: 1.5rem;
}

.feature .watch{
    cursor: pointer;
    display: flex;
    align-items: center;
}


.loading {
    cursor: wait;
}

@media(max-width: 82rem){
    .feature {
        min-height: 5rem;
    }

    .feature .watch {
        align-items: flex-end;
        height: 50%; 
        right: 1rem;
        left: auto;
        top: 0;
    }
    
    .feature .details {
        bottom: 0;
        line-height: 3rem;
        right: 1rem;
        width: auto;
        left: auto;
    }
}

@media(max-width: 75rem){

    .features-list__header{
        display: none;
    }

    .features {
        height: 100%;
    }

    .feature{
        position: relative;
        display: initial;
        min-height: 9rem;
    }
    
    .feature p {
        line-height: initial;
        position: absolute;
    }

    .id{
        top: 0.7rem;
        width: auto;
    }

    p.title {
        position: static;
        margin-top: 3rem;
        width: calc(100% - 14rem);
    }

    .voted {
        left: auto;
        right: calc(3% + 19rem);
        top: 0.7rem;
        width: 5rem;
    }

    .voted::before{
        content: 'Voted: ';
    }

    p.my-votes {
        left: auto;
        right: calc(3% + 7rem);
        top: 0.7rem;
        justify-content: space-around;
        width: auto;
    }

    p.my-votes::before{
        content: 'My Votes: ';
    }

    .completion{
        left: auto;
        right: 1rem;
        top: 2.5rem;
        width: auto;
    }

    .completion::before{
        content: 'Completion Date: ';
    }
    

    .feature .watch{
        top: auto;
        bottom: 2.5rem;
    }

    .status-container {
        position: absolute;
        top: 0.7rem;
        right: 1rem;
    }}

@media(max-width:30rem){

    .feature {
        min-height: 10.5rem;
    }

    .voted {
        right: 13rem;
    }

    p.my-votes {
        right: 1rem;
    }

    .title{
        width: 43%;
    }
    
    .status-container{
        top: 4.5rem;
    }
    
    p.title {
        margin-top: 4.5rem;
        width: calc(100% - 7rem);
    }


    
}

@media(max-width:24rem){

    .voted {
        top: 2.6rem;
        right: auto;
        left: 1rem;
    }

    p.my-votes {
        top: 2.6rem;
    }

    .completion{
        top: 0.7rem;
    }

    

}
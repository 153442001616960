.login-wrapper {
    position: relative;
    height: 100vh;
    top: 0;
    width: 100vw;
    left: 0;
    overflow: hidden;
}

.login-background {
    background-image: url(../../../images/BG.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    position: absolute;
    left: 0;
    width: 47%;
    height: 100%;
    top: 0;
    color: #FFFFFF;
    font-size: 1.375rem;
}

.login-background img {
    margin-top: 20vh;
}

.login-right {
    background-color: #0E181D;
    position: absolute;
    height: 100vh;
    top: 0;
    width: 53%;
    left: 47%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.login-form {
    width: 100%;
    max-width: 800px;
    padding: 0 12rem 0rem 12rem;
    overflow: auto;
}

.login-form h1 {
    text-align: center;
    color: #D7E0F5;
    margin-bottom: 3rem;
    margin-left: -6rem;
    margin-right: -6rem;
}

.login-title {
    white-space: nowrap;
}

.logo-right{
    max-width: 22rem;
    max-height: 20rem;
}
@media (max-width: 65rem) {
    

    .login-background img {
        width: 18rem;
    }
    .login-form {
        padding: 0 2rem 0 2rem;
    }

    .login-right > img {
        margin-top: 5vh;
        margin-bottom: 1vh;
        width: 8rem;
    }

    .login-form h1 {
        margin-right: 0;
        margin-left: 0;
    }

}


@media (max-width: 40rem){
.login-wrapper {
    overflow: auto;
}

    .login-background{
        display: none;
    }

    .login-right{
        width: 100%;
        left: 0;
        height: auto;
        min-height: 100vh;
        padding-bottom: 3rem;
    }

    .login-form {
        padding: 0 2rem 0 2rem;
    }

    .login-right > img {
        margin-top: 6vh;
        margin-bottom: 2vh;
        width: 10rem;
    }
}



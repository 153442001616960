.task {
    background-color: #F5F7FA;
    border-radius: 9px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.info {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 70%;
    flex: 1;
}

.title {
    font-weight: 800;
    font-size: 0.875rem;
    color: #2F353B;
    margin: 0;
    line-height: 1.375rem;
    width: 30%;
    cursor: pointer;
}

.details {
    display: flex;
    gap: 0.5rem;
    flex: 1;
}

.detail {
    font-weight: 350;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin: 0;
    color: #2F353B;
    flex: 1;
}



.parentid {
    cursor: pointer;
    font-weight: 400;
    font-size: 0.75rem;
    color: #4E5D78;
}

.parentid:hover{
    text-decoration: underline;
    color: #2F353B;
}

.headers,
.headers-admin{
  padding-right: 176px;
  padding-top: 0;
  background-color: #FFFFFF;
  border-radius: 0;
  padding-bottom: 0.75rem;
  z-index: 3;
}

.headers-admin{
    padding-right: 1rem;
}

.headers .details,
.headers-admin .details {
    margin-left: calc(40% + 60px);
}

.headers .details p,
.headers-admin .details p{
    font-weight: 800;
    color: #4E5D78;
}

@media(max-width: 58rem){
    .headers,
    .headers-admin{
        display: none;
    }

    .task{
        align-items: flex-start;
    }

    .title{
        width: calc(100% - 13rem);
    }

    .info{
        flex-wrap: wrap;
    }

    .details{
       order: 1;
    }
}

@media(max-width: 32.5rem){
    .task{
        position: relative;
        min-height: 6rem;
    }

    .title {
        width: calc(100% - 3rem);
    }

    .details {
        flex-direction: column;
        width: calc(100% - 10rem);
        flex: none;
    }
    
}
div.user-details {
    text-align: center;
    padding: 0rem 2rem 2rem 2rem;
    top: 5rem;
    cursor: default;
}

/*.user-details::after {
 content: "";
  position: absolute;
  top: -1.4rem;
  left: 70%;
  border-width: 0.7rem;
  border-style: solid;
  border-color:  transparent transparent  #D9D9D9 transparent;
}*/

.user-details__header {
text-align: left;
line-height: 1.7rem;
font-size: 1.125rem;
font-weight: 900;
margin-top: 1.5rem;
margin-bottom: 1.25rem;
}

.user-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-detail {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: normal;
    margin-top: 1.25rem;
    line-height: 1.5rem;
}

.user-detail__separator {
    border: 1px solid #D9DDE3;
    margin-top: 1.25rem;

}

.logout {
    cursor: pointer;
    color: #1F3161;
    font-weight: 900;
    font-size: 0.94rem;
}

.select-wrapper {
    display: flex;
    justify-content: center;
    width: 49%;
    height: 3rem;
    position: relative;
    text-align: left;
  }
  
  div.select-container {
    width: 100%;
    height: 100%;
  }
  
  .select-button {
    border: 1px solid #D9DDE3;
    border-radius: 8px;
    padding: 0 0.75rem;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-family: inherit;
    direction: ltr;
  }

  .select-button:hover {
    border-color: #70921A;
}

.select-button label {
    font-size: 0.75rem;
    color: #8A94A6;
    line-height: 1rem;
    position: absolute;
    top: 0.25rem;
}

.select-button .select {
    background-color: #fff;
    border: none;
    height: 100%;
    width: 100%;
    padding-top: 1.5rem;
    padding-left: 0;
    color: #2F353B;
    line-height: 1.5rem;
    font-size: 1rem;
    margin: 0;
}

.select-button .select:focus-visible {
    outline: none;
}

.select-arrow {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;

}
  
  
  ul.options {
    border: 1px solid #DCDFE3;
    border-radius: 8px;
    display: none;
    list-style: none;
    margin-top: 0.6rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    max-height: 25rem;
    overflow: auto;
  }
  
  ul.show {
    display: block;
  }
  
  ul.options li {
    background: #FFFFFF;
    height: 3.75rem;
    line-height: 3.75rem;
    cursor: pointer;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
overflow: hidden;
  }

  ul.options li:first-of-type {
    border-radius: 8px 8px 0 0 ;
  }

  ul.options li:last-of-type {
    border-radius: 0 0 8px 8px;
  }
  
  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover {
    background: #F4F4F4;;
  }

  ul.options li[aria-selected="true"] {
    background-color: #70921A;
    color: #FFFFFF;
  }
  
  .invalid-input{
    background-color:  #FFEAE9;
    border-color: #D72F19;
    color: #8B1A1C;
}

.invalid-input:hover{
  border-color: #D72F19;
}

.invalid-input:focus-within {
    border-color: #D72F19;
}

.invalid-input input:focus {
    color: #8B1A1C;
}

.invalid-input .select {
    background-color: #FFEAE9;
}

.invalid-input label {
    color: #9b0709;
}


.modal {
    display: inherit;
    position: absolute;
    top: 0;
    width: 62%;
    left: 38%;
    background-color: #F5F7FA;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    overflow: auto;
    height: calc(100% - 5rem);
    z-index: 21;
}

.modal-back,
.mobile-back {
    position: absolute;
    top: 2.5%;
    left: 33.3%;
    cursor: pointer;
}

.modal-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0E181D;
    height: 6.25rem;
    padding-left: 1.875rem;
}

.modal-title h2 {
    color: #FFFFFF;
    font-weight: 800;
    margin: 1.25rem 0 0.3rem 0;
}

.modal-title p {
    color: #B0B7C3
}

.details-section {
    margin: 8.2rem 1.875rem 0 1.875rem;
}

.mobile-close {
    display: none;
    position: absolute;
    right: 1.5rem;
    top: 1.625rem;
    height: 0.94rem;
    width: 0.94rem;
    cursor: pointer;
}

.mobile-back {
    display: none;
    left: 30%;
}

@media (max-width: 65rem) {

    .modal-back {
        display: none;
    }

    .mobile-back {
        display: inherit;
        left: 15.5%;
    }

    .modal{
        width: 80%;
        left: 20%;
    }


}

@media (max-width: 40rem) {
    .modal {
        width: 100%;
        left: 0;
    }

    .details-section {
        margin: 7.19rem 0.94rem 0 0.94rem;
    }

    .mobile-close {
        display: block;
    }

}

@media(max-width: 25rem){
    .modal-title h2 {
        font-size: 1.3rem;
    }
}
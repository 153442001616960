.fixed-bar {
    left: 19%;
}

@media(max-width: 65rem){
    .fixed-bar{
        left: 10%;
    }
}

@media (max-width: 40rem){
    .fixed-bar {
        left: 0;
    }
}
.load{
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 0.75rem;
}

.show-more {
    cursor: pointer;
    margin: 0;
    color: #323B4B;
    font-size: 0.875rem;
    font-weight: 350;
    display: flex;
    align-items: center;
    justify-content: center;}

    .list{
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .sticky{
        position: sticky;
        top: -2rem;
        z-index: 3;
    }

    .header{
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 1.25rem;
        background-color: #FFFFFF;
        padding: 0.75rem 0;
        z-index: 3;
    }
    
    .line{
        height: 1px;
        background-color: #D9DDE3;
        flex: 1;
    }
    
    .view {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #2F353B;
        font-weight: 800;
        font-size: 0.75rem;
        line-height: 1.25rem;
        cursor: pointer;
    }
    
    .view img {
        width: 1rem;
    }

@media(max-width: 40rem){
    .sticky{
        position: initial;
    }
}
.header {
    color:  #2F353B;
}

.description {
    height: 15rem;
}

.success-message {
    color: #2C3C05;
    font-weight: 900;
    font-size: 1.4rem;
  
}

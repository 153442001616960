.modal-title {
    height:4.2rem;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;

}

.modal-title__main {
    margin: 1rem 0rem;
}

.modal-title__close {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-weight: 900;
    font-size: 1.125rem;
}

.modal-title__close img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.mobile-close {
    display: none;
    align-items: center;
}

.mobile-close img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

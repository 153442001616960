.popup {
    background: #FFFFFF;
    position: absolute;
    border: 1px solid #D9DDE3;
    box-shadow: 0px 8px 14px rgba(24, 39, 75, 0.06);
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
    width: 16rem;
    color:  #2F353B;
    z-index: 4;
    right: 0;
    cursor: initial;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem;
  }

  .title{
    color: #8A94A6;
    margin: 0;
    line-height: 1.5rem;
  }


  .note {
    min-height: 8rem;
  }

  .check {
    cursor: pointer;
  }
.modal {
    display: inherit;
    position: absolute;
    top: 10vh;
    width: 62%;
    left: 19%;
    height: calc(100% - 10vh - 5rem);
    z-index: 21;
}

.modal-title {
    /*position: absolute;
    top: 4.5vh;
    left: 19%;
    width: 62%;
    */
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;

}

.modal-title__main {
    margin: 1rem 0rem;
}

.modal-title__close {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-weight: 900;
    font-size: 1.125rem;
}

.modal-title__close img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.mobile-close {
    display: none;
    align-items: center;
}

.mobile-close img {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
}

.modal-white {
    background-color: #F5F7FA;
    border-radius: 16px 16px 0 0 ;
    padding: 1.875rem 1.125rem 1.875rem 3.125rem;
    box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);    z-index: 30;
    overflow: auto;
    height: calc(100% - 4.2rem);
}

.modal form {
    margin-right: 2rem;
}

@media(max-width: 65rem){
    .modal{
        width: 80%;
        left: 10%;
    }
}

@media (max-width: 40rem) {
    .modal {
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
    }

    .modal-title {
        background-color: #C1C7D0;
        color: #2F353B;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .modal-white {
        padding-left: 0.94rem;
        padding-right: 0.94rem;
        padding-top: 0;
        border-radius: 0;
    }

    .modal form {
        margin-right: 0;
    }
    
    .mobile-close {
        display: flex;
    }

    .modal-title__close {
        display: none;
    }
}


.add-button {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-size: 0.94rem;
    font-weight: 900;
    color: #1F3161;
    cursor: pointer;
    width: max-content;
}

.border {
    border: 1px solid #1F3161;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
}

.img {
    width: 1.5rem;
}

.green {
    color: #70921A;
}

@media (max-width: 23rem){
    .border .btn-text {
        display: none;
    }
}

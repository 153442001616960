.spinner-wrapper {
    text-align: center;
    margin-top: 20rem;
    position: fixed;
    z-index: 18;
    width: calc(100% - 12.5rem); 
    left: 12.5rem;   
    display: flex;
    align-items: center;
    justify-content: center;}

.loader {
    width: 48px;
    height: 48px;
    border: 3px solid #B1B5BA;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: #637E1F;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

@media(max-width:65rem){
   .spinner-wrapper {
    width: 100%;
    left: 0;
   }
}
.btn-m {
    min-height: 2.25rem;
}

.spinning {
    animation: spinner 3s linear infinite;
}

.red {
    color: #D72F19;
    border-color: #D72F19;
}

.red:hover {
    border-color: #F68686;
    background-color: #FFEAE9;
    color: #8B1A1C;
}

.red:active{
    border-color: #F68686;
    background-color: #F68686;
    color: #8B1A1C;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}



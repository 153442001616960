

.input,
.button {
    opacity: 0;
}

.backdrop{
    display: flex;
    align-items: center;
}

div.popup{
    top: auto
}
.spinner-wrapper {
    display: flex;
    padding-top: 10rem;
    position: absolute;
    margin: auto;
    width: -webkit-fill-available;;
}